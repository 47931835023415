import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleStore } from '@hunter/state-management';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppStoreService {

  private readonly latestError = new SimpleStore<any>();
  private readonly featureError = new SimpleStore<any>();
  private readonly feature403 = new SimpleStore<string>();
  private readonly feature404 = new SimpleStore<string>();

  constructor(
    private readonly toastrService: ToastrService
  ) {

  }

  setNewError(error: any) {
    this.latestError.setState(error);
  }

  getLatestError() {
    return this.latestError.getLastState();
  }

  errorToastMessageSetup(config: Partial<IndividualConfig>) {

    return this.latestError.state$.pipe(
      filter((newError: any) => {
        let code = 999;
        if (newError instanceof HttpErrorResponse) {
          if (newError.url.includes('suppress-aci=true')) {
            //call had ACI off, should ignore
            code = -1;
          }
          else {
            code = newError.status;
          }
        }
        return code > -1;
      }),
      tap((err) => {
        if (this.toastrService && err) {
          this.toastrService.error(undefined, undefined, config);
        }
      })
    );
  }

  setFeatureError(error: any) {
    this.featureError.setState(error);
  }

  getFeatureError() {
    return this.featureError.state$;
  }

  setFeature403(error: any) {
    this.feature403.setState(error);
  }

  getFeature403() {
    return this.feature403.state$;
  }

  setFeature404(error: any) {
    this.feature404.setState(error);
  }

  getFeature404() {
    return this.feature404.state$;
  }
}
