<div
  id="WorkCenterNav"
  [ngClass]="{ 'hide-features': isCollapsed }"
>
  <mat-accordion
    displayMode="flat"
    multi="true"
    hideToggle="true"
  >
    @for (workcenter of workCenters; track workcenter) {
    <mat-expansion-panel
      [expanded]="expandWorkcenterPanelFromUrlPath(workcenter.Name)"
      class="work-center-panel"
      [disabled]="isCollapsed"
      class="mat-elevation-z0"
    >
      @if (hasPermissionToViewWorkCenterInMenu(workcenter)) {
      <mat-expansion-panel-header [attr.data-workcenter]="workcenter.Name.toLowerCase()">
        <mat-panel-title>
          <img
            [ngClass]="{ hide: isCollapsed }"
            class="icons"
            [src]="'assets/' + workcenter.Icon"
          />
          <span
            [ngClass]="{ hide: isCollapsed }"
            [attr.data-cy]="workcenter.DataCy"
          >{{ workcenter.Name }}</span>
          <button
                  [ngClass]="{ hide: !isCollapsed }"
                  class="menu-button"
                  #menuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="workCenterMenu"
                  (click)="setOpenedWorkCenter(menuTrigger)"
                  >
                  <img class="icons" [src]="'assets/' + workcenter.Icon"/>
                </button>
          <mat-menu
            #workCenterMenu="matMenu"
            class="collapsed-work-center-menu"
            [hasBackdrop]="false"
            [ngClass]="{ hide: false }"
          >
            <mat-toolbar>{{ workcenter.Name }}</mat-toolbar>
            @for (feature of workcenter.Features; track feature) {
            <div>
              @if (hasPermissionToViewFeature(feature) && feature.DisplayInMenu === 'True') {
              <a
                mat-menu-item
                [href]="'/' + workcenter.Route + feature.Route"
                queryParamsHandling="merge"
              >{{ feature.Name }}</a>
              }
            </div>
            }
          </mat-menu>
        </mat-panel-title>
      </mat-expansion-panel-header>
      }
      @for (feature of workcenter.Features; track feature) {
      @if (hasPermissionToViewFeature(feature)) {
      <a
        [attr.data-cy]="feature.DataCy"
        class="feature-label"
        [ngClass]="{ 'active-feature': isActiveFeature(workcenter.Route, feature.Route) }"
        [href]="'/' + workcenter.Route + feature.Route"
        queryParamsHandling="merge"
        (click)="expandedMenuFeatureClick(feature)"
      >{{ feature.Name }}</a>
      }
      }
    </mat-expansion-panel>
    }

  </mat-accordion>
</div>
