import { HttpErrorResponse } from '@angular/common/http';

export class NotificationCustomError implements Error {
  readonly name = 'NotificationCustomError';
  readonly message: string;
  readonly error: any | null;
  readonly status?: number;
  readonly statusText?: string;
  readonly ok = false;
  constructor(init: {
      error?: HttpErrorResponse | any;
      status?: number;
      statusText?: string;
      message?: string;
  }) {
    this.error = init.error;
    this.status = init.status;
    this.statusText = init.statusText;
    this.message = init.message;
  }
}
