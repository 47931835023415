<h1 mat-dialog-title>Unsupported Browser Detected</h1>
<mat-dialog-content
  class="dialog-window"
  data-cy="browser-dialog-window"
>
  <div class="list-title">
    Some or all of the features may not work. Consider downloading a compatible
    browser from the list below:
  </div>
  <ul>
    @for (browser of browserDownloadPages | keyvalue; track browser) {
    <li>
      <a
        href={{browser.value}}
        target="_blank"
      >{{browser.key}}</a>
    </li>
    }
    <!-- <li><a href="https://www.google.com/chrome/downloads/" target="_blank">Chrome</a></li>
    <li><a href="https://www.mozilla.org/en-US/firefox/new/?redirect_source=getfirefox-com" target="_blank">Firefox</a></li>
    <li><a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a></li>
    <li><a href="https://support.apple.com/downloads/safari" target="_blank">Safari</a></li> -->
  </ul>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</mat-dialog-content>
