<button mat-icon-button [matMenuTriggerFor]="menu" data-cy="help-center-button">
  <mat-icon>help</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div data-cy="help-center-dropdown">
    <button mat-menu-item (click)="openSupportDialog()" data-cy="new-support-ticket-button">
      <span>New Support Ticket</span>
    </button>
    @if (documentation?.length) {
    <a
      [href]="documentation"
      target="_blank"
      mat-menu-item
    >
      Documentation
    </a>
    }
  </div>
</mat-menu>
