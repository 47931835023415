import { HttpParams, HttpHeaders } from '@angular/common/http';
class HunterApiConfig {
  /**
   * Sets the base portion of the URL used to access all Hunter APIs in the current environment.
   * @param baseUrl The base URL for Hunter's API services (e.g. https://api-dev.hunter.com/). An ending slash is optional.
   */
  static setHunterApiBaseUrl(baseUrl) {
    if (!baseUrl) {
      throw new Error('Base URL should not be null/undefined/empty');
    }
    this.theBaseUrl = baseUrl;
  }
  /**
   * @returns The base portion of the URL used to access all Hunter APIs in the current environment.
   */
  static getHunterApiBaseUrl() {
    return this.theBaseUrl;
  }
  /**
   * @internal
   * Clears the base URL for unit testing.
   */
  static clearHunterApiBaseUrl() {
    this.theBaseUrl = null;
  }
}
class AbstractControllerService {
  /**
   * Constructs a full API URL by prepending the base URL to the provided path.
   * @param path The API endpoint path. An initial '/' is optional.
   */
  buildUrl(path) {
    if (!HunterApiConfig.getHunterApiBaseUrl()) {
      throw new Error('Hunter API base URL is undefined/null/empty. Set using HunterApiConfig.setHunterApiBaseUrl.');
    }
    // The replace converts duplicate forward slashes (/) into single slashes. This allows us to not care about trailing slashes in the base URL or initial
    // slashes in the path. It ignores duplicates preceded by a colon (i.e., it ignores https://).
    return `${HunterApiConfig.getHunterApiBaseUrl()}/${path}`.replace(/(https?:\/\/)|(\/){2,}/ig, '$1$2');
  }
  /**
   * Finds any required parameters that were not provided.
   * @param params The parameters passed to an operation.
   * @param required The keys required to exist.
   * @returns An array of required keys not found in params.
   */
  getMissingRequiredParameters(params, required) {
    if (!required || required?.length <= 0) {
      return [];
    }
    if (!params) {
      return required;
    }
    const keys = Object.keys(params);
    return required.filter(x => !keys.includes(x));
  }
  /**
   * Creates an HTTP query parameters with the standard Hunter parameters initialize.
   * @param params An object containing the parameters to add.
   * @param keys The keys of the values to add from the params object.
   * @param version The API version
   * @param suppressAci Controls whether or not the API Call Indicator UI element is displayed.
   * @returns The initialized HttpParams
   */
  buildQueryParams(version, suppressAci, params, keys) {
    let queryParams = new HttpParams();
    queryParams = this.addToHttpParamsRecursive(queryParams, version, 'api-version');
    if (suppressAci) {
      queryParams = this.addToHttpParamsRecursive(queryParams, true, 'suppress-aci');
    }
    if (params && keys) {
      for (let key of keys) {
        queryParams = this.addToHttpParamsRecursive(queryParams, params[key], key);
      }
    }
    return queryParams;
  }
  /**
   * Creates a FormData instance with specific parameters.
   * @param params An object containing the parameters to add.
   * @param keys The keys of the values to add from the params object.
   */
  buildFormData(params, keys) {
    let formData = new FormData();
    if (params === null || params === undefined) {
      throw Error('params are required for form data');
    }
    if (!keys) {
      throw Error('keys are required for form data');
    }
    for (let key of keys) {
      if (!params[key]) {
        // This is not an error because optional parameters are a thing.
        // The client should be calling getMissingRequiredParameters to validate the all required parameters exist.
        continue;
      }
      formData.append(key, params[key]);
    }
    return formData;
  }
  /**
   * Creates an HttpHeaders instance for a request.
   * @param options The request options passed to the operation. May be null/undefined.
   * @param acceptOptions A list of MIME types the operation can accept.
   * @param consumeOptions A list of MIME types the endpoint can consume.
   * @param bearerToken The JWT bearer token.
   * @param params An object containing the parameters to add.
   * @param keys The keys of the values to add from the params object.
   */
  buildRequestHeaders(options, acceptOptions, consumeOptions, bearerToken, params, keys) {
    let headers = new HttpHeaders();
    const accept = options?.accept || this.getBestMimeType(acceptOptions);
    if (accept) {
      headers = headers.set('Accept', accept);
    }
    // Ignore 'multipart/form-data' because the HttpClient will add the correct value if it is needed.
    const contentType = this.getBestMimeType(consumeOptions);
    if (contentType && contentType != 'multipart/form-data') {
      headers = headers.set('Content-Type', contentType);
    }
    if (bearerToken) {
      headers = headers.set('Authorization', `Bearer ${bearerToken}`);
    }
    if (params && keys) {
      for (let key of keys) {
        if (!params[key.key]) {
          // This is not an error because optional parameters are a thing.
          // The client should be calling getMissingRequiredParameters to validate the all required parameters exist.
          continue;
        }
        headers = headers.set(key.header, String(params[key.key]));
      }
    }
    if (options?.progressMessage) {
      headers = headers.set('X-Hunter-ACI-Message', `${options.progressMessage.verb} ${options.progressMessage.noun}`);
    }
    return headers;
  }
  /**
   * Accepts multiple types of value:
   *   - atomics: adds the value with key as the query parameter name.
   *   - dates: adds the value (converted to ISO 8601 string format) with key as the query parameter name.
   *   - arrays: adds each element with key as the query parameter name (multiple params with the same name).
   *   - objects: adds each element of the object. If a name is supplied, the query parameters name is <name>.<key>,
   *              otherwise <key> is used.
   * @param params The instance to append query parameters to.
   * @param value The value to appen.
   * @param name The name of the query parameter to insert. Unused if value is an object.
   * @returns A new params instance with the value appended to the source.
   */
  addToHttpParamsRecursive(params, value, name) {
    if (!value) {
      return params;
    }
    if (!name && (typeof value !== "object" || value instanceof Date)) {
      throw Error("name may not be null/undefined for Dates and non-object query parameters");
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => params = this.addToHttpParamsRecursive(params, elem, name));
      } else if (value instanceof Date) {
        params = params.append(name, value.toISOString());
      } else {
        Object.keys(value).forEach(k => params = this.addToHttpParamsRecursive(params, value[k], name ? `${name}.${k}` : k));
      }
    } else {
      params = params.append(name, value);
    }
    return params;
  }
  getBestMimeType(types) {
    if (!types || types.length === 0) {
      return undefined;
    }
    const type = types.find(x => x.endsWith('/json'));
    return type !== undefined ? type : types[0];
  }
}

/*
 * Public API Surface of service-library
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractControllerService, HunterApiConfig };
