<h1 mat-dialog-title>Ticket Submitted</h1>
<div mat-dialog-content>
  <p>Thank you for your support request. Someone from IT will be contacting you shortly to discuss your issue. For reference, your ticket will appear in <a
      [href]="serviceDeskUserUrl"
      target="_blank"
    >Jira</a> momentarily.</p>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button mat-button type="button" (click)="onOkClick()">OK</button>
</div>
