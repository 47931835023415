import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { NotificationIconComponent } from './components/notification-icon/notification-icon.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationOverlayComponent } from './components/notification-overlay/notification-overlay.component';
import { NotificationService } from './services/notification.service';
import { NOTIFICATION_OVERLAY_DATA } from './services/notification-overlay.injection-token';
import { NotificationOverlayService } from './services/notification-overlay.service';
import { SubscriptionService } from './services/subscription.service';
import { NotificationsStore } from './stores/notifications.store';

@NgModule({
  declarations: [NotificationIconComponent, NotificationOverlayComponent, NotificationListComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatBadgeModule,
    MatListModule,
    OverlayModule,
  ],
  exports: [
    NotificationIconComponent
  ],
  providers: [
    { provide: NOTIFICATION_OVERLAY_DATA, useValue: {} },
    NotificationOverlayService,
    NotificationService,
    SubscriptionService,
    NotificationsStore
  ]
})
export class NotificationsModule {}
