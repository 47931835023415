import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { CalculateRoiComponent } from 'src/app/features/calculate-roi/calculate-roi.component';
import { CustomerInfoComponent } from 'src/app/features/customer-info/customer-info.component';
import { DocumentDownloadComponent } from 'src/app/features/document-download/document-download.component';
import { ManageDemoEquipmentComponent } from 'src/app/features/manage-demo-equipment/manage-demo-equipment.component';
import { ManageDistributorsComponent } from 'src/app/features/manage-distributors/manage-distributors.component';
import { ManageFeatureFlagsComponent } from 'src/app/features/manage-feature-flags/manage-feature-flags.component';
import { ManageNotificationsComponent } from 'src/app/features/manage-notifications/manage-notifications.component';
import { ManageReleaseNotesComponent } from 'src/app/features/manage-release-notes/manage-release-notes.component';
import { ManageSurveysComponent } from 'src/app/features/manage-surveys/manage-surveys.component';
import { OnboardRepsComponent } from 'src/app/features/onboard-reps/onboard-reps.component';
import { PowerBiReportsComponent } from 'src/app/features/power-bi-reports/power-bi-reports.component';
import { ReviewCommissionsComponent } from 'src/app/features/review-commissions/review-commissions.component';
import { SurveyPrototypeComponent } from 'src/app/features/survey-prototype/survey-prototype.component';
import { UserPermissionsComponent } from 'src/app/features/user-permissions/user-permissions.component';
import { UserProfileComponent } from 'src/app/features/user-profile/user-profile.component';
import { UserSettingsComponent } from 'src/app/features/user-settings/user-settings.component';
import { WidgetPrototypeComponent } from 'src/app/features/widget-prototype/widget-prototype.component';

import { Error40xComponent } from '../components/error40x/error40x.component';
import { FakeErrorComponent } from '../components/fake-error/fake-error.component';
import { CentralServiceWorkOrdersComponent } from '../features/central-service-work-orders/central-service-work-orders.component';
import { InternalPartImagesComponent } from '../features/internal-part-images/internal-part-images.component';
import { ManageBannersComponent } from '../features/manage-banners/manage-banners.component';
import { ManageQuickLinksComponent } from '../features/manage-quick-links/manage-quick-links.component';
import { OpenTerritorySalesCallComponent } from '../features/open-territory-sales-call/open-territory-sales-call.component';
import { OperationResourcesComponent } from '../features/operation-resources/operation-resources.component';
import { PaymentPortalComponent } from '../features/payment-portal/payment-portal.component';
import { ProductionSchedulingComponent } from '../features/production-scheduling/production-scheduling.component';
import { QuoteBuilderComponent } from '../features/quote-builder/quote-builder.component';

export interface IWebComponentFeatureRoute {
  routeSegments: string[];
  component: any;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private microAppBaseUrl: string;

  private readonly webComponentRoutes: IWebComponentFeatureRoute[] = [
    { routeSegments: ['Profile'], component: UserProfileComponent, title: 'Profile' },
    { routeSegments: ['Settings'], component: UserSettingsComponent, title: 'Settings' },
    { routeSegments: ['IT', 'ManageNotifications'], component: ManageNotificationsComponent, title: 'Manage Notifications' },
    { routeSegments: ['IT', 'UserPermissions'], component: UserPermissionsComponent, title: 'User Permissions' },
    { routeSegments: ['IT', 'WidgetPrototype'], component: WidgetPrototypeComponent, title: 'Widget Prototype' },
    { routeSegments: ['IT', 'ManageReleaseNotes'], component: ManageReleaseNotesComponent, title: 'Manage Release Notes' },
    { routeSegments: ['IT', 'ManageFeatureFlags'], component: ManageFeatureFlagsComponent, title: 'Manage Feature Flags' },
    { routeSegments: ['IT', 'ManageBanners'], component: ManageBannersComponent, title: 'Manage Banners' },
    { routeSegments: ['IT', 'ManageQuickLinks'], component: ManageQuickLinksComponent, title: 'Manage Quick Links' },
    { routeSegments: ['Distributor', 'ManageUsers'], component: ManageDistributorsComponent, title: 'Manage Distributors' }, // Needed for old legacy apps to redirect to Manage Distributors
    { routeSegments: ['Distributor', 'ManageDistributors'], component: ManageDistributorsComponent, title: 'Manage Distributors' },
    { routeSegments: ['Reporting', 'PowerBIReports'], component: PowerBiReportsComponent, title: 'Power BI Reports' },
    { routeSegments: ['Account', 'ManageDemoEquipment'], component: ManageDemoEquipmentComponent, title: 'Manage Demo Equipment' },
    { routeSegments: ['Account', 'OpenTerritorySalesCall'], component: OpenTerritorySalesCallComponent, title: 'Open Territory Sales Call' },
    { routeSegments: ['Account', 'PaymentPortal'], component: PaymentPortalComponent, title: 'Payment Portal' },
    { routeSegments: ['Account', 'ReviewCommissions'], component: ReviewCommissionsComponent, title: 'Review Commissions' },
    { routeSegments: ['Field', 'OnboardReps'], component: OnboardRepsComponent, title: 'Onboard Reps' },
    { routeSegments: ['Sales', 'QuoteBuilder'], component: QuoteBuilderComponent, title: 'Quote Builder' },
    { routeSegments: ['Sales', 'CalculateRoi'], component: CalculateRoiComponent, title: 'Calculate ROI' },
    { routeSegments: ['Documents', 'Downloads'], component: DocumentDownloadComponent, title: 'Document Download' },
    { routeSegments: ['Customers', 'CustomerInfo'], component: CustomerInfoComponent, title: 'Customer Info' },
    { routeSegments: ['Customers', 'Survey'], component: SurveyPrototypeComponent, title: 'Customer Survey' },
    { routeSegments: ['Customers', 'ManageSurveys'], component: ManageSurveysComponent, title: 'Manage Surveys' },
    { routeSegments: ['Parts', 'InternalPartImages'], component: InternalPartImagesComponent, title: 'Internal Part Images' },
    { routeSegments: ['Operations', 'Resources'], component: OperationResourcesComponent, title: 'Operation Resources' },
    { routeSegments: ['Operations', 'ProductionScheduling'], component: ProductionSchedulingComponent, title: 'Production Scheduling ' },
    { routeSegments: ['Service', 'CentralServiceWorkOrders'], component: CentralServiceWorkOrdersComponent, title: 'Central Service Work Orders ' },
  ];

  constructor(
    private readonly router: Router
  ) {
  }

  /**
   * Adds configuration data to the router.
   * @param configData The configuration data.
   */
  addConfigData(configData) {
    this.router.config[0].data = configData;
  }

  /**
   * Adds all of the LaunchPad routes to the router.
   * @param microAppBaseUrl The base URL where all micro-app files are found.
   */
  addRoutes(microAppBaseUrl: string) {
    this.microAppBaseUrl = microAppBaseUrl;
    this.router.config[0].children = this.createRoutes();
  }

  private createWebComponentFeatureRoute(webComponentFeatureRoute: IWebComponentFeatureRoute) {
    return {
      matcher: (url) => {
        if (url.length >= webComponentFeatureRoute.routeSegments.length) {
          let matchingRoute = true;
          webComponentFeatureRoute.routeSegments.forEach((routeSegment, i) => {
            if (url[i].path.toLowerCase() !== routeSegment.toLowerCase()){
              matchingRoute = false;
            }
          });
          if (matchingRoute) {
            return {
              consumed: url
            };
          }
        }
        return null;
      },
      component: webComponentFeatureRoute.component,
      data: { title: webComponentFeatureRoute.title }
    };
  }

  private createWebComponentFeatureRoutes(): Routes {
    const convertedRoutes = this.webComponentRoutes.map((featureRoute) => this.createWebComponentFeatureRoute(featureRoute));
    return convertedRoutes;
  }

  private createDefaultRoute(): Route {
    return {
      path: '',
      children: [
        { path: '404', component: Error40xComponent },
        { path: '403', component: Error40xComponent },
        { path: 'fake-error-page', component: FakeErrorComponent }
      ]
    };
  }

  private createRoutes() {
    return [
      this.createDefaultRoute(),
      ...this.createWebComponentFeatureRoutes()
    ];
  }

}
