import { Component, OnInit } from '@angular/core';
import { AppStoreService } from 'src/app/app-store.service';

import { FeatureCommonService } from '../feature-common.service';

@Component({
  selector: 'app-manage-surveys',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class:'set-height' },
  template: `@if (initialized) {<manage-surveys (featureErrorEvent)="handleError($event)" (feature403Event)="handle403($event)" (feature404Event)="handle404($event)"></manage-surveys>}`,
})
export class ManageSurveysComponent implements OnInit {
  initialized = false;
  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly featureCommonService: FeatureCommonService
  ) {}

  ngOnInit() {
    this.initialized = this.featureCommonService.initialize('manage-surveys');
  }

  handleError(event){
    this.appStoreService.setFeatureError(event.detail);
  }

  handle403(event){
    this.appStoreService.setFeature403(event.detail);
  }

  handle404(event){
    this.appStoreService.setFeature404(event.detail);
  }

}
