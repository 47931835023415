<div data-cy="timeout-snackbar">
  @if (data.type === 'Session') {
  <div>
    <span>Your session will expire in {{formattedTimeRemaining}} </span>
    <button mat-button (click)="takeAction()" data-cy="extend-session-button">Extend Session</button>
  </div>
  }

  @if (data.type === 'Token') {
  <div>
    <span>You will be logged out in {{formattedTimeRemaining}} </span>
    <button mat-button (click)="takeAction()" data-cy="log-out-now-button">Log Out Now</button>
  </div>
  }
</div>
