import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { localAppConfig } from '../app-config';
import { FeatureLoaderDialogComponent } from './feature-loader-dialog/feature-loader-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FeatureCommonService {

  constructor(
    private readonly dialog: MatDialog
  ) { }

  initialize( webComponentTag: string) {
    const customElementUrl = `${localAppConfig.MicroAppBaseUrl}${webComponentTag}/${webComponentTag}.js?v=1.0`;
    if (!this.isScriptLoaded(customElementUrl)) {
      return this.addScriptToBody(customElementUrl);
    }
    return true;
  }

  private isScriptLoaded(src) {
    return document.querySelector('script[src="' + src + '"]') ? true : false;
  }

  private addScriptToBody(src) {
    const dialogRef = this.dialog.open(FeatureLoaderDialogComponent, {
      width: '350px',
      disableClose: true
    });
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      dialogRef.close();
    };
    return true;
  }
}
