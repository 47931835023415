import { Component, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationOverlayRef } from '../../models/notification-overlay-ref';
import { NotificationService } from '../../services/notification.service';
import { NotificationsStore } from '../../stores/notifications.store';

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss']
})
export class NotificationOverlayComponent implements OnInit {

  notificationCount$: Observable<number>;

  private readonly delayForHidingOverlay = 2000;

  constructor(
    private readonly overlayRef: NotificationOverlayRef,
    private readonly notificationService: NotificationService,
    private readonly notificationsStore: NotificationsStore
  ) {}

  ngOnInit() {
    this.notificationCount$ = this.notificationsStore.unreadNotificationsCount$.pipe(
      tap((count) => {
        if (count === 0 && this.overlayRef.isOpen()) {
          this.closeEmptyOnDelay();
        }
      })
    );
  }

  close() {
    this.overlayRef.close();
  }

  dismissAll() {
    this.notificationService.clearNotifications();
    this.close();
  }

  private closeEmptyOnDelay() {
    timer(this.delayForHidingOverlay)
      .subscribe(() => {
        this.close();
      });
  }

}
