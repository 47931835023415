<h2 mat-dialog-title>Release Notes</h2>
<mat-dialog-content>
  @if (data.releaseNotes; as releaseNotes) {
  @for (releaseNote of releaseNotes; track releaseNote) {
  <mat-card appearance="raised">
    <mat-card-content>
      <mat-card-title>{{releaseNote.FeatureName ? releaseNote.FeatureName : releaseNote.Type}} - {{releaseNote.Date}}</mat-card-title>
      @for (note of releaseNote.Notes; track note) {
      <ul>
        <li>{{ note }}</li>
      </ul>
      }
    </mat-card-content>
  </mat-card>
  }
  }
</mat-dialog-content>
<div
  mat-dialog-actions
  align="end"
>
  <button mat-button type="button" mat-dialog-close>CLOSE</button>
</div>
