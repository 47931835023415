import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { datadogRum, RumFetchResourceEventDomainContext, RumXhrResourceEventDomainContext } from '@datadog/browser-rum';
import { ApiCallIndicatorInterceptorService, ApiCallIndicatorModule } from '@hunter/api-call-indicator';
import { AuthenticationLibModule, JwtInterceptorService } from '@hunter/authentication';
import { CommonDialogModule } from '@hunter/common-dialog';
import { ConfigLoaderModule, ConfigLoaderService } from '@hunter/config-loader';
import { HunterApiConfig } from '@hunter/service-library';
import { SpoofUserModule } from '@hunter/spoof-user';
import { StateManagementModule } from '@hunter/state-management';
import { ConfigurationServiceModule } from '@hunter-service-libraries/configuration-service';
import { LaunchPadServiceModule } from '@hunter-service-libraries/launch-pad-service';
import { UserServiceModule } from '@hunter-service-libraries/user-service';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { localAppConfig, setAppConfig } from './app-config';
import { AppErrorHandler } from './app-error-handler.service';
import { AppRoutingModule } from './app-routing.module';
import { BannerComponent } from './components/banner/banner.component';
import { BrowserDialogComponent } from './components/browser-dialog/browser-dialog.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { Error40xComponent } from './components/error40x/error40x.component';
import { FakeErrorComponent } from './components/fake-error/fake-error.component';
import { HelpMenuComponent } from './components/help-menu/help-menu.component';
import { LaunchPadContainerComponent } from './components/launch-pad-container/launch-pad-container.component';
import { ProfileIconComponent } from './components/profile-icon/profile-icon.component';
import { ReleaseNotesDialogComponent } from './components/release-notes-dialog/release-notes-dialog.component';
import { ReleaseNotesNotificationsComponent } from './components/release-notes-notifications/release-notes-notifications.component';
import { SettingsIconComponent } from './components/settings-icon/settings-icon.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SignoutComponent } from './components/signout/signout.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { TicketSubmittedDialogComponent } from './components/ticket-submitted-dialog/ticket-submitted-dialog.component';
import { TimeoutSnackBarComponent } from './components/timeout-snack-bar/timeout-snack-bar.component';
import { UsersnapComponent } from './components/usersnap/usersnap.component';
import { FeaturesModule } from './features/features.module';
import { LayoutComponent } from './layout/layout/layout.component';
import { MaterialModule } from './material.module';
import { NotificationsModule } from './notifications/notifications.module';
import { CallBlockerService } from './services/call-blocker.service';
import { FeatureCommonEventsService } from './services/feature-common-events.service';
import { RoutesService } from './services/routes.service';
import { SharedErrorHandlerService } from './services/shared-error-handler.service';

const appInitializerFn = (appConfigService: ConfigLoaderService) => {
  return async () => {
    return appConfigService.loadConfig('app').then((config) => {
      setAppConfig(config); // sets local app config to app-config.json of the launchpad
      HunterApiConfig.setHunterApiBaseUrl(localAppConfig.BaseUrl);

      if(['dev', 'qa', 'prod'].includes(localAppConfig.Name)) {
        datadogRum.init({
          applicationId: 'f770c878-5b5b-4f2c-a9f2-f4876c92dcd2',
          clientToken: 'pub3bb9a21d7fcede580ad56aeec30f1e67',
          site: 'datadoghq.com',
          service:'launchpad',
          env: localAppConfig.Name,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: '1.0.0',
          sampleRate: 100,
          premiumSampleRate: 100,
          trackInteractions: true,
          trackFrustrations: true,
          defaultPrivacyLevel:'mask-user-input',
          allowedTracingOrigins: [localAppConfig.BaseUrl],
          beforeSend: (event, context: any) => {
            if (event.type === 'resource' ) {
              if (event.resource.type === 'fetch') {
                const fetchContext: RumFetchResourceEventDomainContext = context;
                event.context = { ...event.context, responseHeaders: fetchContext.response.headers };
              }
              if (event.resource.type === 'xhr') {
                const xhrContext: RumXhrResourceEventDomainContext = context;
                const headers = xhrContext.xhr.getAllResponseHeaders();
                const headdersArray = headers.trim().split(/[\r\n]+/);

                // Create a map of header names to values
                const responseHeaders = {};
                headdersArray.forEach((line) => {
                  const parts = line.split(': ');
                  const header = parts.shift();
                  const value = parts.join(': ');
                  responseHeaders[header] = value;
                });
                event.context = { ...event.context, responseHeaders };
              }
            }
            if(event.type === 'error' && event.error.message.includes('ResizeObserver')) {
              // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
              return false;
            }
          },
        });

        datadogRum.startSessionReplayRecording();
        datadogRum.startView();

        const userName = localStorage.getItem('user') || '';
        const spoof = localStorage.getItem('spoof') || '';

        datadogRum.setUser({
          id: userName,
          spoofedBy: spoof
        });

      }
    });
  };
};

@NgModule({ declarations: [
  AppComponent,
  SignoutComponent,
  LayoutComponent,
  SettingsIconComponent,
  TimeoutSnackBarComponent,
  Error40xComponent,
  SupportDialogComponent,
  TicketSubmittedDialogComponent,
  HelpMenuComponent,
  SidenavComponent,
  ProfileIconComponent,
  CustomToastComponent,
  UsersnapComponent,
  FakeErrorComponent,
  ReleaseNotesNotificationsComponent,
  ReleaseNotesDialogComponent,
  BrowserDialogComponent,
  LaunchPadContainerComponent,
  BannerComponent
],
bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
  ConfigLoaderModule.forRoot({ logging: false }),
  ApiCallIndicatorModule.forRoot({ logging: false, showLoadingMessage: true, showRequestStatuses: true }),
  AppRoutingModule,
  AuthenticationLibModule.forRoot({ logging: false }),
  ServiceWorkerModule.register('custom-ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  LaunchPadServiceModule,
  UserServiceModule,
  ConfigurationServiceModule,
  StateManagementModule,
  CommonDialogModule,
  ToastrModule.forRoot(),
  FeaturesModule,
  MaterialModule,
  NotificationsModule,
  ReactiveFormsModule,
  FormsModule,
  SpoofUserModule], providers: [
  ConfigLoaderService,
  RoutesService,
  SharedErrorHandlerService,
  FeatureCommonEventsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CallBlockerService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiCallIndicatorInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptorService,
    multi: true
  },
  {
    provide: ErrorHandler,
    useClass: AppErrorHandler
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [ConfigLoaderService]
  },
  provideHttpClient(withInterceptorsFromDi())
] })
export class AppModule { }
