<div
  id="NotificationOverlayBodyContainer"
  data-cy="notification-dropdown"
>
  <mat-card appearance="raised">
    <mat-card-title>My Notifications</mat-card-title>
    <mat-card-content>
      <app-notification-list></app-notification-list>
    </mat-card-content>
    <mat-card-actions align="end">
      @if (notificationCount$ | async) {
      <button mat-button (click)="dismissAll()">
          CLEAR ALL
        </button>
      }
      <button mat-button (click)="close()" data-cy="notification-close-button">CLOSE</button>
    </mat-card-actions>
  </mat-card>
</div>
