import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { localAppConfig } from 'src/app/app-config';

import { TimeoutSnackBarComponent } from '../components/timeout-snack-bar/timeout-snack-bar.component';
import { LockDownReasonEnum } from '../models/lock-down-reason-enum';
import { LockDownService } from './lock-down.service';
import { SessionExpirationService } from './session-expiration.service';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {

  private readonly EXPIRATION_LOCAL_STORE_ID = 'expires'; // Token expiration

  tokenExpiration = 0;

  constructor(
    private readonly sessionExpirationService: SessionExpirationService,
    private readonly snackBar: MatSnackBar,
    private readonly lockDownService: LockDownService
  ) { }

  setupTokenChecking() {
    this.tokenExpiration = + localStorage.getItem(this.EXPIRATION_LOCAL_STORE_ID);
    const currentTime = this.sessionExpirationService.getCurrentTimeInSeconds();
    if (this.isTokenValid(currentTime)) {
      if (this.isTokenAboutToExpire(currentTime)) {
        const timeRemaining = this.tokenExpiration - currentTime;
        this.showTokenTimeoutWarning(timeRemaining);
      }
      else{
        this.setUpCountdownToExpirationWarning();
        this.sessionExpirationService.setupSessionChecking();
      }
    }
    else {
      this.lockDownService.lockUserDown(LockDownReasonEnum.Token);
    }
  }

  isTokenValid(currentTime: number): boolean {
    if (this.tokenExpiration > currentTime ) {
      return true;
    }
    return false; // This should never happen. If the token is expired it should be caught by the AuthGuard
  }

  isTokenAboutToExpire(currentTime: number): boolean {
    if ((this.tokenExpiration - currentTime) <= localAppConfig.SessionExpirationWarning ){
      return true;
    }
    return false;
  }

  private setUpCountdownToExpirationWarning() {
    const milisecondsUntilWarning = this.sessionExpirationService.getMilisecondsUntilWarningShouldShow(this.tokenExpiration);
    this.lockDownService.addTimeout(window.setTimeout(this.showTokenTimeoutWarning.bind(this), milisecondsUntilWarning));
  }

  private showTokenTimeoutWarning(timeRemaining?: number) {
    if (!this.lockDownService.isUserLockedDown()) { // Don't show snackbar if user is already locked down
      let duration = localAppConfig.SessionExpirationWarning;
      if (timeRemaining) {
        duration = timeRemaining;
      }
      // this timeout is necessary if user is not on the tab when the snackbar duration should have ended
      // snackbar duration does not start if the user isn't on the tab
      window.setTimeout(this.lockUserDownWhenWarningExpires.bind(this), duration * 1000);
      const snackBarRef: MatSnackBarRef<TimeoutSnackBarComponent> = this.snackBar.openFromComponent(TimeoutSnackBarComponent, {
        duration: duration * 1000,
        data: {
          timeRemaining: duration,
          type: 'Token'
        }
      });

      snackBarRef.afterDismissed().subscribe((response) => {
        if (response.dismissedByAction) {
          this.lockDownService.refreshJWT();
        }
      });
    }
  }

  private lockUserDownWhenWarningExpires() {
    this.snackBar.dismiss(); // incase the the user was not on the tab at the time of token expiration
    this.lockDownService.lockUserDown(LockDownReasonEnum.Token);
  }

}
