@if (type === 'error' && appError) {
<div>
  <div
    class="custom-toast-container"
    data-cy="error-dialog"
  >
    <div class="title-bar">
      <h1 class="mat-h1">
        {{ toastHeaderTitle }}
      </h1>
      <a (click)="remove()"><mat-icon>close</mat-icon></a>
    </div>
    <div
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
    >
      <div>
        {{ toastHelpfulMessage }}
      </div>
      @if (appError?.error?.Errors?.length) {
      <div>
        <div class="error-list">
          <div class="error-list-header">Response Message</div>
          <ul>
            @for (error of appError.error.Errors; track error) {
            <li>
              <div>
                @for (item of error | keyvalue; track item) {
            <li>
              @if (displayErrorProperty(item.value)) {
              <div>
                <b>{{item.key}}: </b> {{item.value}}
              </div>
              }
            </li>
            }
        </div>
        </li>
        }
        </ul>
      </div>
    </div>
    }
    @if (showAdvanced) {
    <div class="advanced-properties">
      <ul>
        @if (appError.name) {
        <li data-cy="error-type"><b>Type: </b>{{ appError.name }}</li>
        }
        @if (appError.status) {
        <li data-cy="error-status"><b>Status: </b>{{ appError.status }}</li>
        }
        @if (appError.message) {
        <li data-cy="error-message"><b>Message: </b>{{ appError.message }}</li>
        }
        @if (appError.error.RequestId) {
        <li data-cy="error-request-id"><b>Request Id: </b>{{ appError.error.RequestId }}</li>
        }
      </ul>
    </div>
    }
    <div class="bottom-row-container">
      <div>
        <a
          data-cy="create-support-ticket-button"
          (click)="openErrorTicketDialog()"
          mat-button
          color="primary"
        >CREATE TICKET</a>
      </div>
      <div class="advanced-button">
        <button mat-stroked-button (click)="showAdvanced = !showAdvanced" data-cy="toggle-advanced">ADVANCED</button>
      </div>
    </div>
  </div>
</div>
</div>
}
