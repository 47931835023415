import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AuthenticationService } from '@hunter/authentication';
import { UserNotificationsControllerService } from '@hunter-service-libraries/user-service';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Notification } from '../models/notification';
import { NotificationCustomError } from '../models/notification-custom-error';
import { NotificationsStore } from '../stores/notifications.store';

@Injectable()

export class NotificationService {
  rxSubscriptions: Subscription[] = [];

  userId: string;

  constructor(
    private readonly swPush: SwPush,
    private readonly authenticationService: AuthenticationService,
    private readonly userNotificationsService: UserNotificationsControllerService,
    private readonly notificationsStore: NotificationsStore
  ) {
    this.userId = this.authenticationService.getAuthUserId();
  }

  getNotificationsAndUpdateStore() {
    this.notificationsStore.notifications.setState(this.getNotifications$());
  }

  private getNotifications$() {
    return this.userNotificationsService.v1.getNotifiationsAsync({ userId: this.userId }, { suppressAci: true }) as Observable<Notification[]>;
  }

  subscribeToMessageStream() {
    return this.swPush.messages.subscribe(() => this.getNotificationsAndUpdateStore());
  }

  clearNotifications() {
    const notifications = this.notificationsStore?.notifications.getLastState();
    if (notifications.length) {
      notifications.forEach((v, i) => {
        const notification = notifications[i];
        if (notification.DismissedOn === null) {
          notification.DismissedOn = new Date().toISOString();
          this.userNotificationsService.v1.updateNotificationAsync(
            { userId: this.userId, notificationId: notification.Id, notification: notification },
            { suppressAci: true }
          ).pipe(
            catchError((error) => {
              return throwError(new NotificationCustomError({ error,
                status: 888,
                statusText: 'General application error' }));
            })
          )
            .subscribe();
        }
      });
      this.notificationsStore?.notifications.setState(notifications);
    }
  }

  clearNotification(notificationId: string) {
    if (notificationId != null && notificationId !== '') {

      const notificationToClear = this.notificationsStore.getSingleNotification(notificationId);

      notificationToClear.DismissedOn = new Date().toISOString();

      if (notificationToClear.UserId != null && notificationToClear.UserId !== '') {
        this.userNotificationsService.v1.updateNotificationAsync(
          { userId: this.userId, notificationId: notificationToClear.Id, notification: notificationToClear },
          { suppressAci: true }
        ).pipe(
          catchError((error) => {
            return throwError(new NotificationCustomError({ error,
              status: 888,
              statusText: 'General application error' }));
          })
        )
          .subscribe(
            (_response: Notification) => {
              this.notificationsStore.updateSingleNotification(notificationToClear);
            }
          );
      }
    }
  }

}
