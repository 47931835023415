import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Banner } from 'src/app/models/banner';
import { BannerLocationEnum } from 'src/app/models/banner-location-enum';
import { BannerTypeIconEnum } from 'src/app/models/banner-type-icon-enum';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit{

  banners: Banner[] = [];
  location: string;

  @Input()
  set _banners(banners: Banner[]) {
    this.banners = this.locationFilteredBanners(banners);
  }


  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.location = this.router.url;
  }


  getIcon(banner: Banner): string{
    return BannerTypeIconEnum[banner.Type];
  }

  locationFilteredBanners(banners: Banner[]): Banner[] {
    let workCenter = '';
    let feature = '';
    // landing page
    if(this.location !== '/'){
      workCenter = this.location?.split('/')[1].toLowerCase();
      feature = this.location?.split('/')[2]?.toLowerCase();

    }


    const onlyBannerMatchingRequestedType = banners?.filter((banner) => {
      return banner.Location === BannerLocationEnum.Sitewide ||
            (banner.WorkCenter && banner.Location === BannerLocationEnum.WorkCenter && banner.WorkCenter.toLowerCase() === workCenter) ||
            (banner.Application && banner.Location === BannerLocationEnum.Application && banner.Application.replace(/\s+/g, '').toLowerCase() === feature);
    });

    return onlyBannerMatchingRequestedType;
  }

}
