import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationLibModule, AuthGuardService, LoginComponent, LogoutComponent } from '@hunter/authentication';
import { caseInsensitiveUrlMatcher, CaseInsensitiveUrlMatcherModule } from '@hunter/case-insensitive-url-matcher';

import { SignoutComponent } from './components/signout/signout.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { PermissionGuardService } from './services/permission-guard.service';
import { SessionGuardService } from './services/session-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService, SessionGuardService],
    canActivateChild: [PermissionGuardService]

  },
  { matcher: caseInsensitiveUrlMatcher('signout'), component: SignoutComponent },
  { matcher: caseInsensitiveUrlMatcher('login'), component: LoginComponent },
  { matcher: caseInsensitiveUrlMatcher('logout'), component: LogoutComponent },
  { path: '**', redirectTo: '404' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    // RouterModule.forRoot(routes, { enableTracing: true, relativeLinkResolution: 'corrected' }), // Swap this for previous line to see verbose routing log in console -MP
    AuthenticationLibModule.forRoot({ logging: false }),
    CaseInsensitiveUrlMatcherModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
