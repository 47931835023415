import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AuthenticationService } from '@hunter/authentication';
import { SubscriptionModel, SubscriptionsControllerService, UserSubscriptionsControllerService } from '@hunter-service-libraries/user-service';
import { BehaviorSubject, forkJoin, from, Observable, of, timer } from 'rxjs';
import { delayWhen, map, retryWhen, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SwSubscription } from '../models/subscription';


@Injectable()
export class SubscriptionService {

  private readonly retryDelay = 10000;
  private readonly swSubscriptionsSubject: BehaviorSubject<SwSubscription[]> = new BehaviorSubject<SwSubscription[]>([]);
  private userId = '';

  constructor(
    private readonly swPush: SwPush,
    private readonly authenticationService: AuthenticationService,
    private readonly subscriptionService: SubscriptionsControllerService,
    private readonly userSubscriptionsService: UserSubscriptionsControllerService
  ) {
    this.userId = this.authenticationService.getAuthUserId();
  }

  loadSubscriptions() {
    forkJoin([this.getUsersSubscriptions(this.userId), this.swRequestSubscription$()])
      .pipe(
        tap((data) => {
          const subscriptions: SwSubscription[] = data[0] as SwSubscription[];
          this.swSubscriptionsSubject.next(subscriptions);
        }),
        switchMap((data) => {

          if (data[1] !== null) {
            return forkJoin([of(data), this.saveSubscription(data[1])]);
          }
          else {
            return of(data);
          }
        })
      ).subscribe((data) => {
        if (data[1] != null) {
          this.addSubscription(data[1]);
        }
      });
  }

  subscriptionWithRetry$() {
    return this.swPush.subscription.pipe(
      map((sub) => {
        if (!sub) {
          throw sub;
        }
        return sub;
      }),
      retryWhen((errors) => {
        return errors.pipe(
          delayWhen((val) => {
            return timer(this.retryDelay);
          })
        );
      })
    );
  }

  private getUsersSubscriptions(userId: string): Observable<SubscriptionModel[]> {
    return this.userSubscriptionsService.v1.getSubscriptionsAsync({ userId }, { suppressAci: true });
  }

  private swRequestSubscription$(): Observable<any> {
    const config = { serverPublicKey: environment.publicDevKey };
    if ((window as any).ApplePaySession) { // 2020-01-14|rbarton: we are probably in Safari browser
      console.log('ApplePaySession is on the window object, so we are probably in Safari!');
      return of(null);
    }
    else {
      return from(this.swPush.requestSubscription(config));
    }
  }

  private saveSubscription(sub) {
    const subscription = this.decodeSubscription(this.userId, sub);
    if (!this.authenticationService.isSpoofing()) {
      if (this.isNewSubscription(subscription)) {
        return this.subscriptionService.v1.insertSubscription({ anys: subscription }, { suppressAci: true });
      }
      else {
        return of(null);
      }
    }
    return of(null);
  }

  private isNewSubscription(subscription) {

    const isNewSubscription = this.swSubscriptionsSubject.value
      .filter((sub) =>
        sub.UserId === subscription.UserId &&
        sub.Endpoint === subscription.Endpoint &&
        sub.PublicKey === subscription.PublicKey &&
        sub.Token === subscription.Token
      )
      .length <= 0;

    return isNewSubscription;
  }

  private addSubscription(subscription) {
    const subscriptions = this.swSubscriptionsSubject.value;
    subscriptions.push(subscription);
    this.swSubscriptionsSubject.next(subscriptions);
  }

  private decodeSubscription(userId: string, sub: PushSubscription) {

    // 2019-12-04|rbarton: PushSubscription keys explained = https://developer.mozilla.org/en-US/docs/Web/API/PushSubscription/getKey
    const key = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh'))));
    const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth'))));

    const subscription = {
      UserId: userId,
      Endpoint: sub.endpoint,
      PublicKey: key,
      Token: auth
    };

    return subscription;
  }

}
