import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { IFeatureModel } from 'src/app/models/feature-model';
import { IWorkCenterModel } from 'src/app/models/work-center-model';
import { PermissionGuardService } from 'src/app/services/permission-guard.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent{

  @Input() readonly workCenters: IWorkCenterModel[];
  @Input() readonly isCollapsed: boolean;
  @Output() featureClicked = new EventEmitter();

  @Input() private readonly user: UserInformation;
  private menuJustClicked = false;
  private openedWorkCenter: MatMenuTrigger;
  locationPath = '';

  constructor(
    private readonly permissionGuardService: PermissionGuardService,
    private readonly ngLocation: Location
  ) {
    this.locationPath = this.ngLocation.path();
  }

  setOpenedWorkCenter(menuTrigger: MatMenuTrigger) {
    if (this.openedWorkCenter) { // Close Opened Work Center
      this.openedWorkCenter.closeMenu();
      if (this.openedWorkCenter === menuTrigger) { // Check to see if the user is closing the Opened Work Center
        this.openedWorkCenter = null;
      }
      else { // Else the user has opened a differnet Work Center
        this.openedWorkCenter = menuTrigger;
      }
    }
    else { // Set Opened Work Center
      this.openedWorkCenter = menuTrigger;
    }
    this.menuJustClicked = true;
  }

  hasPermissionToViewFeature(feature) {
    return this.permissionGuardService.userHasPermissionToViewFeature(feature, this.user);
  }

  hasPermissionToViewWorkCenterInMenu(workcenter: IWorkCenterModel){
    if (workcenter.Features){
      for (const feature of workcenter.Features) {
        if (this.hasPermissionToViewFeature(feature) && feature.DisplayInMenu === 'True'){
          return true;
        }
      }
      return false;
    }
  }

  expandedMenuFeatureClick(feature: IFeatureModel) {

    this.featureClicked.emit(feature.Route);
  }

  isActiveFeature(workCenter: string, feature: string){
    const cleanedFeature = feature.replace('/', '');
    return this.locationPath.includes(`${workCenter}${cleanedFeature}`);
  }
  expandWorkcenterPanelFromUrlPath(workCenter: string) {
    // 2019-12-05|rbarton: assume url path is: '/Sales/CalculateRoi/Home'...
    // then pull out 'Sales' and now we know what workcenter panel to expand...
    // this is dependent on the path 'Sales' matching up with the workcenter name

    const workCenterDirectRoute = this.locationPath !== '/' ? this.locationPath.split('/')[1] : null;
    if(workCenterDirectRoute === workCenter){
      return true;
    }
    return false;
  }
}
