import { Injectable } from '@angular/core';
import { SimpleStore } from '@hunter/state-management';
import { Observable } from 'rxjs';

import { IFeatureModel } from '../models/feature-model';

@Injectable({
  providedIn: 'root'
})
export class SettingsStoreService {

  private readonly settings = new SimpleStore<any>();
  private readonly currentFeature = new SimpleStore<IFeatureModel>();

  constructor() { }

  setSettings(settings: any): void {
    this.settings.setState(settings);
  }

  getSettings(): Observable<any> {
    return this.settings.state$;
  }

  setCurrentFeature(feature: IFeatureModel): void {
    this.currentFeature.setState(feature);
  }

  getCurrentFeature(): Observable<IFeatureModel> {
    return this.currentFeature.state$;
  }

}
