import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { SupportDialogComponent } from 'src/app/components/support-dialog/support-dialog.component';
import { TicketSubmittedDialogComponent } from 'src/app/components/ticket-submitted-dialog/ticket-submitted-dialog.component';
import { ISupportFormModel } from 'src/app/models/support-form-model';
import { HelpService } from 'src/app/services/help.service';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketService {

  private supportForm: ISupportFormModel = {
    SupportTicket: {
      Subject: '',
      Description: '',
      Data : {}
    },
    ProblemTypes: []
  };
  constructor(
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly helpService: HelpService
  ) { }

  openSupportDialog(problemTypes: string[], route?: string, data?: {[key: string]: string;}): void {

    if (!data){
      data = {};
    }

    if (!route){
      route = this.getFullRoute();
    }

    if (route){
      data['Route'] = route;
    }

    data['NavigatorProperties'] = JSON.stringify(this.getNavigatorProperties(), null, 2);

    this.supportForm.SupportTicket.Data = data;
    this.supportForm.ProblemTypes = problemTypes;

    this.dialog.open(SupportDialogComponent, {
      data: this.supportForm,
      width: '500px',
      panelClass: 'support-dialog',
      disableClose: true
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.helpService.SubmitSupportTicket(result).subscribe((response) =>{
          this.openTicketSubmittedDialog();
        });
      }
    });
  }

  private openTicketSubmittedDialog() {
    this.dialog.open(TicketSubmittedDialogComponent, {
      width: '500px',
      disableClose: true
    });
  }

  getFullRoute() : string {
    const startingRoute = this.activatedRoute.snapshot.url.toString();

    return startingRoute + this.getChildRoutes(this.activatedRoute.snapshot);
  }

  private getChildRoutes(activatedRoute: ActivatedRouteSnapshot) : string {
    let currentRoute = '';
    if (activatedRoute.firstChild !== null ) {
      if (activatedRoute.firstChild.url.length) {
        currentRoute = '/' + activatedRoute.firstChild.url.join('/');
      }

      currentRoute += this.getChildRoutes(activatedRoute.firstChild);
    }
    return currentRoute;
  }

  private getNavigatorProperties() : any {
    let props = {};
    ['userAgent', 'language', 'cookieEnabled', 'abc'].forEach((key) => {
      props[key] = navigator[key];
    });
    return props;
  }
}
