import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { Observable } from 'rxjs/internal/Observable';
import { BrowserService } from 'src/app/services/browser.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UserService } from 'src/app/services/user.service';
import { SettingsStoreService } from 'src/app/stores/settings-store-service.service';
import { UserStoreService } from 'src/app/stores/user-store.service';

import { BrowserDialogComponent } from '../../components/browser-dialog/browser-dialog.component';
import { TokenExpirationService } from '../../services/token-expiration.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  currentUser$: Observable<UserInformation>;
  launchPadSettings$: Observable<any>;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly settingsStoreService: SettingsStoreService,
    private readonly tokenExpirationService: TokenExpirationService,
    private readonly userService: UserService,
    private readonly userStoreService: UserStoreService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly dialog: MatDialog,
    private readonly browserService: BrowserService,


  ) {
    this.userService.getCurrentUser();
    this.settingsService.getLaunchPadSettings();
    this.tokenExpirationService.setupTokenChecking();
    this.iconRegistry.addSvgIcon('release-note', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/release_note.svg'));
  }

  ngOnInit() {
    this.currentUser$ = this.userStoreService.getCurrentUser();
    this.launchPadSettings$ = this.settingsStoreService.getSettings();
    this.handleUnsupportedBrowser();
  }

  private handleUnsupportedBrowser(): void {
    if (!this.browserService.isBrowserAccepted()) {
      this.dialog.open(BrowserDialogComponent, {
        autoFocus: false,
        width: "500px",
        disableClose: false,
      });
    }
  }
}
