import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStoreService } from 'src/app/app-store.service';
import { IFeatureModel } from 'src/app/models/feature-model';

import { FeatureCommonService } from '../feature-common.service';

@Component({
  selector: 'app-manage-demo-equipment',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class:'set-height' },
  template: `@if (initialized) {<manage-demo-equipment (featureErrorEvent)="handleError($event)" (feature403Event)="handle403($event)" (feature404Event)="handle404($event)"></manage-demo-equipment>}`,
})
export class ManageDemoEquipmentComponent implements OnInit {
  initialized = false;
  feature$: Observable<IFeatureModel>;

  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly featureCommonService: FeatureCommonService
  ) {}

  ngOnInit() {
    this.initialized = this.featureCommonService.initialize('manage-demo-equipment');
  }

  handleError(event){
    this.appStoreService.setFeatureError(event.detail);
  }

  handle403(event){
    this.appStoreService.setFeature403(event.detail);
  }

  handle404(event){
    this.appStoreService.setFeature404(event.detail);
  }

}
