import { Component } from "@angular/core";
import { BrowserDownloadPageEnum } from "src/app/models/browsers-download-page";

@Component({
  selector: "app-browser-dialog",
  templateUrl: "./browser-dialog.component.html",
  styleUrls: ["./browser-dialog.component.scss"],
})
export class BrowserDialogComponent {
  browserDownloadPages = BrowserDownloadPageEnum;

  constructor() {}
}
