import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@hunter/authentication';
import { CommonDialogComponent, DialogType, ICommonDialogConfig } from '@hunter/common-dialog';
import { filter } from 'rxjs/operators';
import { IActivityListeners } from 'src/app/models/activity-listeners';
import { LockDownReasonEnum } from 'src/app/models/lock-down-reason-enum';

@Injectable({
  providedIn: 'root'
})
export class LockDownService {
  private readonly SESSION_EXPIRATION_LOCAL_STORE_ID = 'sessionExpires';
  private readonly JWT_LOCAL_STORE_ID = 'jwt';

  private lockDownMessageShown = false;

  timeouts = [];
  sessionExpirationService;

  activitiyListeners: IActivityListeners = {
    onMouseMove: '',
    onMouseDown: '',
    onKeyPress: '',
    onTouchMove: '',
  };

  constructor(
    private readonly router: Router,
    private readonly locationService: Location,
    private readonly dialog: MatDialog,
    private readonly authenticationService: AuthenticationService
  ) {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // if the user is signed out, we dont need to check for activity or session/token expiration
      if (event.urlAfterRedirects === '/signout') {
        this.removeActivityListeners();
        this.timeouts.forEach((timeout) => window.clearTimeout(timeout));
      }
    });
  }

  setActivityListeners(activitiyListeners: IActivityListeners) {
    this.activitiyListeners = activitiyListeners;
  }

  addTimeout(timeoutId: number) {
    this.timeouts.push(timeoutId);
  }

  removeTimeout(timeoutId: number) {
    window.clearTimeout(timeoutId);
    this.timeouts = this.timeouts?.filter((timeout) => timeout !== timeoutId);
  }

  isUserLockedDown() {
    if (this.lockDownMessageShown){
      return true;
    }
    return false;
  }

  lockUserDown(reason: LockDownReasonEnum) {
    this.openSessionExpiredMessage(reason);
    this.removeActivityListeners();
    this.timeouts.forEach((timeout) => window.clearTimeout(timeout));
  }

  refreshJWT(originalLocation?: string) {
    let returnUrl = '';
    if (!originalLocation) {

      returnUrl = window.location.href;
    }
    else if (window.location.href.includes(originalLocation)){
      returnUrl = window.location.href;
    }
    else {
      originalLocation = originalLocation.replace('/', '');
      returnUrl = `${window.location.href}${originalLocation}`;
    }

    localStorage.removeItem(this.JWT_LOCAL_STORE_ID);
    localStorage.removeItem(this.SESSION_EXPIRATION_LOCAL_STORE_ID);
    this.authenticationService.login(returnUrl);
  }

  private openSessionExpiredMessage(reason: LockDownReasonEnum): void {
    if (!this.isUserLockedDown()) { // Only show dialog once.
      this.lockDownMessageShown = true;
      const lockDownMessage = this.getLockDownMessage(reason);
      const dialogConfig: ICommonDialogConfig = {
        Type: DialogType.Message,
        Title: `${reason} Expired`,
        Message: lockDownMessage
      };
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        data: dialogConfig,
        width: '300px',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshJWT();
      });
    }
  }

  private getLockDownMessage(reason: LockDownReasonEnum): string {
    if (reason === LockDownReasonEnum.Session){
      return 'Your session has expired due to inactivity.';
    }
    if (reason === LockDownReasonEnum.Token){
      return 'Your token has expired.';
    }
  }

  private removeActivityListeners() {
    document.removeEventListener('mousemove', this.activitiyListeners.onMouseMove, false);
    document.removeEventListener('mousedown', this.activitiyListeners.onMouseDown, false);
    document.removeEventListener('keypress', this.activitiyListeners.onKeyPress, false);
    document.removeEventListener('touchmove', this.activitiyListeners.onTouchMove, false);
  }
}
