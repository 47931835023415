<button id="openProfileMenuButton"
  [matMenuTriggerFor]="menu"
  [style.background-image]="profileIconImage"
  data-cy="profile-picture"
  >
</button>

@if (isSpoofing) {
<div class="spoof-container">
  <img
    class="spoof"
    src="assets/moustache-and-glasses.svg"
  >
</div>
}

<mat-menu
  #menu="matMenu"
  data-cy="profile-picture-dropdown"
>
  <a
    href="/Profile/Main"
    mat-menu-item
    data-cy="view-profile-button"
  >
    <span>View Profile</span>
  </a>
  <lib-spoof-menu-item
    [currentUser]="currentUser"
    data-cy="spoof-button"
  ></lib-spoof-menu-item>
  <lib-end-spoof-menu-item></lib-end-spoof-menu-item>
  <button mat-menu-item (click)="signoutLink()" data-cy="sign-out-button">
      <span>Sign Out</span>
    </button>
</mat-menu>
