import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { AppStoreService } from 'src/app/app-store.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedErrorHandlerService {
  private infiniteLoopCounter = 0;
  private errorTimestamp = Date.now();
  private permissionDeniedErrors = {
    CHROME: 'registration failed - permission denied',
    ELECTRON: 'registration failed - push service not available',
    FIREFOX: 'user denied permission to use the push api.',
  };
  private NOT_ALLOWED_ERROR_NAME = 'NotAllowedError';
  private ABORT_ERROR = 'AbortError';
  private readonly countLimit = 8;
  private readonly oneThirdOfASecond = 333;
  private readonly infiniteLoopMessage = `An error occurred that we weren't anticipating. Please submit a ticket to support@hunter.com for quicker, personalized assistance.`;
  private init = false;

  constructor(private readonly appStoreService: AppStoreService) {
    this.appStoreService.getFeatureError().subscribe((error) => {
      this.handleError(error);
    });
  }

  initialize() {
    this.init = true;
  }

  handleError(error) {
    const theError = error.originalError || error;
    console.warn('Error Caught in Shared Error Handler: ', theError);

    const isRapidError = this.checkIfNewErrorsAreHappeningRapidly(Date.now());
    // 2020-02-03|rbarton: if errors are happening in succession within a third of a second we are assuming we are in a loop...
    // ... after 8 times the handleError method is called in very quick succession then we show user browser alert...
    // ... and stop trying to show the Angular Toast message (because Angular might be locked up)
    if (isRapidError) {
      this.handleRapidErrors(theError);
      this.infiniteLoopCounter += 1;
    }
    else {
      this.sendError(theError);
      this.infiniteLoopCounter = 0;
    }

    this.errorTimestamp = Date.now();
  }

  private checkIfNewErrorsAreHappeningRapidly(timeNow: number) {
    return timeNow - this.errorTimestamp < this.oneThirdOfASecond;
  }

  private handleRapidErrors(theError: any) {
    if (this.infiniteLoopCounter === this.countLimit) {
      alert(this.infiniteLoopMessage);
    }
    else if (this.infiniteLoopCounter > this.countLimit) {
      console.log('Stuck in a loop.');
    }
    else if (this.infiniteLoopCounter < this.countLimit) {
      this.sendError(theError);
    }
  }

  sendError(error: any){
    if (this.isPermissionDeniedError(error)) {
      return;
    }
    else {
      if (environment.production) {
        datadogRum.addError(error);
      }
      this.appStoreService.setNewError(error);
    }
  }

  private isPermissionDeniedError(theError) {
    let isPermissionDeniedError = false;
    if (typeof theError === 'object'){
      if(theError.name === this.NOT_ALLOWED_ERROR_NAME) {
        isPermissionDeniedError = true;
      }
      else if (Object.values(this.permissionDeniedErrors).includes(theError.message.toLowerCase())) {
        isPermissionDeniedError = true;
      }
    }
    else if (typeof theError === 'string' && Object.values(this.permissionDeniedErrors).includes(theError.toLowerCase())) {
      isPermissionDeniedError = true;
    }
    return isPermissionDeniedError;
  }

}
