import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LockDownService } from './lock-down.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuardService  {

  private readonly SESSION_EXPIRATION_LOCAL_STORE_ID = 'sessionExpires';

  constructor(
    private readonly lockDownService: LockDownService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const sessionExpiration = localStorage.getItem(this.SESSION_EXPIRATION_LOCAL_STORE_ID);
    const currentTime = Math.floor(new Date().getTime() / 1000.0);
    if (!sessionExpiration) { // This should only happen after they've logged in
      return true;
    }
    else if (+sessionExpiration <= currentTime) { // Session has expired
      this.lockDownService.refreshJWT(state.url);
      return false;
    }
    return true; // Session is valid
  }

}
