import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '@hunter/authentication';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { localAppConfig } from 'src/app/app-config';


@Component({
// eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent implements OnChanges, OnInit{
  @Input() currentUser: UserInformation;
  isSpoofing = false;
  profileIconImage: SafeStyle;
  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.isSpoofing =  this.authenticationService.isSpoofing();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentUser = changes.currentUser.currentValue;
    this.profileIconImage = this.buildProfileIconImage();
  }

  buildProfileIconImage() {
    const defaultUserIcon = '/assets/default-user-image.png';
    if (this.currentUser.UserId != null && this.currentUser.UserId !== '') {
      const userImage = `${localAppConfig.BaseUrl}/UserService/api/Users/${this.currentUser.UserId}/Image?api-version=2.0`;
      return this.domSanitizer.bypassSecurityTrustStyle(`url(${userImage}), url(${defaultUserIcon})`);
    }
    return this.domSanitizer.bypassSecurityTrustStyle(`url(${defaultUserIcon})`);

  }

  signoutLink() {
    this.router.navigate(['/signout'], { queryParams: [],  replaceUrl: true  }); // replaceUrl true prevents user from going back in history
  }

}
