import { Injectable } from '@angular/core';

import { UserAgentEnum } from '../models/useragent-enum';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  acceptedBrowsers: RegExp[] = [
    /chrome|chromium|crios/i,
    /firefox|fxios/i,
    /edg/i,
    /safari/i,
    /iPad|iPhone|iPod/i
  ];

  constructor() { }

  browserIs(browser: UserAgentEnum): boolean {
    return RegExp(browser).test(navigator.userAgent.toLowerCase());
  }

  isBrowserAccepted(): boolean {
    const userAgent = navigator.userAgent;
    return this.acceptedBrowsers.some((browser) => userAgent.match(browser));
  }

}
