import { Injectable } from '@angular/core';
import { HelpControllerService } from '@hunter-service-libraries/launch-pad-service';

import { ISupportTicketModel } from '../models/support-ticket-model';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(
    private readonly helpControllerService: HelpControllerService
  ){}

  SubmitSupportTicket(supportTicket: ISupportTicketModel) {
    return this.helpControllerService.v2.putSupportTicket(
      { ticket: supportTicket },
      { progressMessage: { verb: 'Creating', noun: 'support ticket' } });
  }
}
