import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IReleaseNote } from 'src/app/models/release-note';

@Component({
  selector: 'app-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss']
})

export class ReleaseNotesDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { releaseNotes: IReleaseNote[] },
  ) { }

}
