import { Injectable } from '@angular/core';
import { SettingsControllerService, SubjectTypes } from '@hunter-service-libraries/configuration-service';

import { SettingsStoreService } from '../stores/settings-store-service.service';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private readonly settingsControllerV1Service: SettingsControllerService,
    private readonly settingsStoreService: SettingsStoreService
  ) { }

  getLaunchPadSettings() {
    this.settingsControllerV1Service.v1.getSettings(
      { subjectType: SubjectTypes.Application, subjectName: 'launch-pad' },
      { progressMessage: { verb: 'Retrieving', noun: 'application settings' } }
    ).subscribe((settings) => {
      this.settingsStoreService.setSettings(settings);
    });
  }

}
