import { Injectable } from '@angular/core';
import { SimpleStore } from '@hunter/state-management';
import { map } from 'rxjs/operators';

import { Notification as HunterNotification } from '../models/notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationsStore {

  readonly notifications = new SimpleStore<HunterNotification[]>();
  readonly unreadNotifications$ = this.notifications.state$.pipe(
    map((data: HunterNotification[]) => this.sortNotificationsByRecent(data.filter((n) => n.DismissedOn == null)))
  );
  readonly unreadNotificationsCount$ = this.unreadNotifications$.pipe(
    map((data: HunterNotification[]) => data.length)
  );

  getSingleNotification(notificationId: string): HunterNotification {
    if (notificationId) {
      const notifications = this.notifications.getLastState();
      const filtered = notifications.filter((notif) => notif.Id === notificationId);
      return filtered.length ? filtered[0] : null;
    }
    return null;
  }

  updateSingleNotification(notification: HunterNotification) {
    if (notification != null && notification.Id != null) {
      const notifications: HunterNotification[] = this.notifications.getLastState();
      const i = notifications.findIndex((v) => v.Id === notification.Id);
      if (i > -1) {
        notifications[i] = notification;
        this.notifications.setState(notifications);
        return true;
      }
    }
    return false;
  }

  private sortNotificationsByRecent(notifications: HunterNotification[]): HunterNotification[] {
    if (notifications) {
      return notifications.sort(
        (a: HunterNotification, b: HunterNotification) => {
          return new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime();
        }
      );
    }
  }

}
