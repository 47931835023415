@for (notification of notifications; track notification; let i = $index) {
@if (notification.Title !== notification.Description) {
<mat-card
  appearance="raised"
  [ngClass]="{'notification-actionable': notification.ActionUrl}"
  (click)="routeNotificationAction(notification.Id, notification.ActionUrl, $event)"
>
  <mat-card-content>
    <div>
      <div class="title"> {{notification.Title}} </div>
      <div> {{notification.Description}} </div>
    </div>
    <button class="clear-button" mat-icon-button (click)="clearNotification(notification.Id, $event)">
          <mat-icon>close</mat-icon>
        </button>
  </mat-card-content>
</mat-card>
}
}

@if (notifications.length === 0) {
<div>Nothing new here!</div>
}
