import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable, Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { localAppConfig } from 'src/app/app-config';
import { UserAgentEnum } from 'src/app/models/useragent-enum';
import { BrowserService } from 'src/app/services/browser.service';

import { NotificationService } from '../../services/notification.service';
import { NotificationOverlayService } from '../../services/notification-overlay.service';
import { SubscriptionService } from '../../services/subscription.service';
import { NotificationsStore } from '../../stores/notifications.store';

@Component({
  selector: 'app-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationIconComponent implements OnInit, OnDestroy {

  notificationCount$: Observable<number>;

  private readonly initialWaitToStart = 1000;
  private readonly refreshInterval = localAppConfig.NotificationRefreshInterval ? localAppConfig.NotificationRefreshInterval : 10000;

  private readonly rxSubs = new Subscription();

  constructor(
    private readonly notificationOverlayService: NotificationOverlayService,
    private readonly notificationService: NotificationService,
    private readonly notificationsStore: NotificationsStore,
    private readonly subscriptionService: SubscriptionService,
    private readonly swPush: SwPush,
    private readonly browserService: BrowserService) {
  }

  ngOnInit() {
    this.startNotificationSystem();
    this.notificationCount$ = this.notificationsStore.unreadNotifications$.pipe(map((all) => all.length));
  }

  ngOnDestroy() {
    this.rxSubs.unsubscribe();
  }

  openNotificationOverlay(origin: HTMLButtonElement) {
    this.notificationOverlayService.open(origin);
  }

  private startNotificationSystem() {
    if (this.swPush.isEnabled && !this.browserService.browserIs(UserAgentEnum.Crios)) {
      this.subscriptionService.loadSubscriptions();
    }
    else {
      console.warn('Service workers not enabled, notifications will be served on interval.');
    }

    const timerSub = timer(this.initialWaitToStart, this.refreshInterval)
      .pipe(takeUntil(this.subscriptionService.subscriptionWithRetry$()))
      .subscribe({
        next: () => this.notificationService.getNotificationsAndUpdateStore(),
        complete: () => this.subscriptionSetupCompletionCallback()
      });
    this.rxSubs.add(timerSub);
  }

  private subscriptionSetupCompletionCallback() {
    this.notificationService.getNotificationsAndUpdateStore();
    this.notificationService.subscribeToMessageStream();
    console.log('Push protocol enabled, serving notifications now.');
  }

}
