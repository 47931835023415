<mt-material-theme></mt-material-theme>
@if (currentUser$ | async; as currentUser) {
@if (launchPadSettings$ | async; as launchPadSettings) {
<app-launch-pad-container
  [_launchPadSettings]="launchPadSettings"
  [_currentUser]="currentUser"
></app-launch-pad-container>
}
}
<app-usersnap></app-usersnap>
