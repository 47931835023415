import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfigLoaderService } from '@hunter/config-loader';
import { IndividualConfig } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { AppStoreService } from './app-store.service';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { FeatureCommonEventsService } from './services/feature-common-events.service';
import { RoutesService } from './services/routes.service';
import { SharedErrorHandlerService } from './services/shared-error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'LaunchPad';
  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly configLoaderService: ConfigLoaderService,
    private readonly routesService: RoutesService,
    private readonly sharedErrorHandlerService: SharedErrorHandlerService,
    private readonly featureCommonEventsService: FeatureCommonEventsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.sharedErrorHandlerService.initialize();
    this.featureCommonEventsService.initialize();
    this.initErrorToastMessages();

    this.configLoaderService.getConfig('app').then((config) => {
      this.routesService.addConfigData(config);
      this.routesService.addRoutes(config.microAppBaseUrl);
    });
  }

  ngOnInit() {
    this.subscribeToRouterForTitle();
  }

  private initErrorToastMessages() {

    const errorToastConfig = {
      tapToDismiss: false,
      disableTimeOut: true,
      toastComponent: CustomToastComponent,
      positionClass: 'toast-center-center'
    } as any as IndividualConfig;

    this.appStoreService.errorToastMessageSetup(errorToastConfig).subscribe();

  }

  private subscribeToRouterForTitle() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.findChildofActivatedRoute(this.activatedRoute);
      rt.data.subscribe((data) => {
        if (data.title) {
          this.title = data.title;
          this.titleService.setTitle(this.title);
        }
      });
    });
  }

  private findChildofActivatedRoute(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.findChildofActivatedRoute(activatedRoute.firstChild);
    }
    return activatedRoute;
  }

}
