import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { localAppConfig } from 'src/app/app-config';

@Component({
  selector: 'app-ticket-submitted-dialog',
  templateUrl: './ticket-submitted-dialog.component.html',
  styleUrls: ['./ticket-submitted-dialog.component.scss']
})
export class TicketSubmittedDialogComponent{

  private readonly serviceDeskUserOpenTicketsPath = 'servicedesk/customer/user/requests?page=1&status=open';
  serviceDeskUserUrl = localAppConfig.ServiceDeskBaseUrl + this.serviceDeskUserOpenTicketsPath;

  constructor(
    public dialogRef: MatDialogRef<TicketSubmittedDialogComponent>
  ) { }

  onOkClick() {
    this.dialogRef.close();
  }

}
