import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { localAppConfig } from 'src/app/app-config';

import { TimeoutSnackBarComponent } from '../components/timeout-snack-bar/timeout-snack-bar.component';
import { IActivityListeners } from '../models/activity-listeners';
import { LockDownReasonEnum } from '../models/lock-down-reason-enum';
import { LockDownService } from './lock-down.service';

@Injectable({
  providedIn: 'root'
})
export class SessionExpirationService {

  private readonly EXPIRATION_LOCAL_STORE_ID = 'expires'; // Token expiration
  private readonly SESSION_EXPIRATION_LOCAL_STORE_ID = 'sessionExpires';


  sessionTimeoutId: number;

  sessionCanBeExtended = true;

  timerReset = false;

  activitiyListeners: IActivityListeners = {
    onMouseMove: '',
    onMouseDown: '',
    onKeyPress: '',
    onTouchMove: '',
  };

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly lockDownService: LockDownService
  ) { }

  setupSessionChecking() {
    const sessionExpiration = localStorage.getItem(this.SESSION_EXPIRATION_LOCAL_STORE_ID);
    if (this.isSessionSet(sessionExpiration)) {
      this.validateSession(+sessionExpiration);
    }
    else { // User just logged in
      this.setSession();
      this.setupActivityListeners();
    }
  }

  private validateSession(sessionExpiration: number){
    const currentTime = this.getCurrentTimeInSeconds();
    if (this.isSessionValid(sessionExpiration, currentTime)) {
      if (this.isSessionAboutToExpire(sessionExpiration, currentTime)) {
        const timeRemaining = sessionExpiration - currentTime;
        this.showSessionTimeoutWarning(timeRemaining);
      }
      else {
        this.setSession();
      }
      this.setupActivityListeners();
    }
    else { // Session is expired
      this.lockDownService.lockUserDown(LockDownReasonEnum.Session);
    }
  }

  isSessionSet(sessionExpiration: string) {
    if (sessionExpiration) { // This should only happen after they've logged in
      return true;
    }
    return false;
  }

  isSessionValid(sessionExpiration: number, currentTime: number): boolean  {
    if (sessionExpiration > currentTime ) {
      return true;
    }
    console.log('session is expired on load');
    return false; // This should never happen. If the session is expired it should be caught by the SessionGuard
  }

  isSessionAboutToExpire(sessionExpiration: number, currentTime: number): boolean {
    if ((sessionExpiration - currentTime) <= localAppConfig.SessionExpirationWarning ){
      return true;
    }
    return false;
  }

  private setupActivityListeners() {
    this.activitiyListeners.onMouseMove = this.resetTimer.bind(this);
    this.activitiyListeners.onMouseDown = this.resetTimer.bind(this);
    this.activitiyListeners.onKeyPress = this.resetTimer.bind(this);
    this.activitiyListeners.onTouchMove = this.resetTimer.bind(this);
    document.addEventListener('mousemove', this.activitiyListeners.onMouseMove, { passive: true });
    document.addEventListener('mousedown', this.activitiyListeners.onMouseDown, { passive: true });
    document.addEventListener('keypress', this.activitiyListeners.onKeyPress, { passive: true });
    document.addEventListener('touchmove', this.activitiyListeners.onTouchMove, { passive: true });
    this.lockDownService.setActivityListeners(this.activitiyListeners);
  }

  private resetTimer() {
    this.lockDownService.removeTimeout(this.sessionTimeoutId);
    this.setSession();
  }

  private setSession() {
    const secondsUntilSessionExpiration = this.getSessionExpirationTime();
    localStorage.setItem(this.SESSION_EXPIRATION_LOCAL_STORE_ID, secondsUntilSessionExpiration.toString());
    this.setUpCountdownToExpirationWarning(secondsUntilSessionExpiration);
  }

  private getSessionExpirationTime(): number {
    const currentTime = this.getCurrentTimeInSeconds();
    let sessionExpirationTime = currentTime + localAppConfig.InactivityTimeLimit;

    const tokenExpirationDate = Number(localStorage.getItem(this.EXPIRATION_LOCAL_STORE_ID));
    if (sessionExpirationTime > tokenExpirationDate) {
      sessionExpirationTime = tokenExpirationDate;
      this.sessionCanBeExtended = false;
    }
    return sessionExpirationTime;
  }

  private setUpCountdownToExpirationWarning(secondsUntilSessionExpiration: number) {
    const milisecondsUntilWarning = this.getMilisecondsUntilWarningShouldShow(secondsUntilSessionExpiration);
    this.sessionTimeoutId = window.setTimeout(this.showSessionTimeoutWarning.bind(this), milisecondsUntilWarning);
    this.lockDownService.addTimeout(this.sessionTimeoutId);
  }

  getCurrentTimeInSeconds() {
    return Math.floor(new Date().getTime() / 1000.0);
  }

  getMilisecondsUntilWarningShouldShow(expirationSeconds: number): number {
    const currentTime = this.getCurrentTimeInSeconds();
    const timeoutInSeconds = expirationSeconds - currentTime - localAppConfig.SessionExpirationWarning;
    return timeoutInSeconds * 1000;
  }

  private showSessionTimeoutWarning(timeRemaining?: number) {
    if (!this.lockDownService.isUserLockedDown() && this.sessionCanBeExtended) { // Don't show this snackbar if the token expiration snackbar should be shown instead
      let duration = localAppConfig.SessionExpirationWarning;
      if (timeRemaining) {
        duration = timeRemaining;
      }
      // this timeout is necessary if user is not on the tab when the snackbar duration should have ended
      // snackbar duration does not start if the user isn't on the tab
      this.timerReset = false;
      window.setTimeout(this.lockUserDownIfSessionNotExtended.bind(this), duration * 1000);
      const snackBarRef: MatSnackBarRef<TimeoutSnackBarComponent> = this.snackBar.openFromComponent(TimeoutSnackBarComponent, {
        duration: duration * 1000,
        data: {
          timeRemaining: duration,
          type: 'Session'
        }
      });

      snackBarRef.afterDismissed().subscribe((response) => {
        if (response.dismissedByAction) {
          this.resetTimer();
          this.timerReset = true;
        }
      });
    }
  }

  private lockUserDownIfSessionNotExtended() {
    if (!this.timerReset) {
      this.snackBar.dismiss(); // incase the the user was not on the tab at the time of session expiration
      this.lockDownService.lockUserDown(LockDownReasonEnum.Session);
    }
  }

}
