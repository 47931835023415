import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Notification } from '../../models/notification';
import { NotificationOverlayRef } from '../../models/notification-overlay-ref';
import { NotificationService } from '../../services/notification.service';
import { NotificationsStore } from '../../stores/notifications.store';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  notifications: Notification[] = [];

  constructor(private readonly notificationService: NotificationService,
              private readonly notificationsStore: NotificationsStore,
              public overlayRef: NotificationOverlayRef,
              private readonly router: Router) {

  }

  ngOnInit() {
    this.notificationsStore.unreadNotifications$.subscribe((notifications: Notification[]) => {
      this.notifications = notifications.slice(0, 50);
    });
  }

  clearNotification(notificationId: string, event: MouseEvent) {
    this.notificationService.clearNotification(notificationId);
    event.stopPropagation();
  }

  routeNotificationAction(notificationId: string, actionUrl: string, event: MouseEvent) {
    if (notificationId && actionUrl && event) {
      event.stopPropagation();
      actionUrl = this.removeHostsFromActionUrl(actionUrl);
      if (actionUrl.indexOf('http') === 0) {
        window.open(actionUrl);
      }
      else {
        this.router.navigateByUrl(actionUrl);
      }
      this.clearNotification(notificationId, event);
      this.overlayRef.close();
      return true;
    }
    return false;
  }

  removeHostsFromActionUrl(actionUrl: string): string {
    const removeHosts = [
      'https://home-dev.hunter.com',
      'https://home-qa.hunter.com',
      'https://home.hunter.com'
    ];
    for (const host of removeHosts) {
      actionUrl = actionUrl.replace(host, '');
    }
    return actionUrl;
  }
}
