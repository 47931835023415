<div
  (click)="openNotificationOverlay(origin)"
  #origin
  data-cy="notification-button"
>
  <button mat-icon-button id="notifcationButton">
    @if (
      notificationCount$ | async; as notificationCount) {
      <mat-icon
        matBadge="{{ notificationCount > 99 ? '99+' : notificationCount }}"
        matBadgePosition="above after"
        [matBadgeHidden]="false"
        >notification_important</mat-icon
        >
      } @else {
        <mat-icon matBadge [matBadgeHidden]="true" >notification_important</mat-icon>
      }
    </button>
</div>
