<div class="lp-container">
  <mat-drawer-container
    (backdropClick)="close()"
    class="drawer-container header-drawer-container"
    autosize
  >
    <mat-drawer
      id="headerDrawer"
      style="overflow: hidden;"
      #drawerHeader
      class="launch-pad-sidenav mat-elevation-z5"
      [ngClass]="{'collapsed-drawer': isCollapsed}"
      [opened]="isOpen"
      [mode]="displayMode"
      #headerDrawerRef
    >
      <div class="hunter-logos">
        <a [href]="homeUrl">
          <img
            data-cy="hunter-logo-button"
            [ngClass]="{ hide: isCollapsed }"
            class="original-hunter-logo"
            src="assets/hunter_logo.png"
            height="36"
            width="159"
          />
        </a>
        <a [href]="homeUrl">
          <img
            class="hunter-h"
            [ngClass]="{ hide: !isCollapsed }"
            src="assets/H.png"
            height="40"
            width="45"
          />
        </a>
        <div
          id="collapseMenuBtn"
          data-cy="collapse-menu-button"
          [ngClass]="{ hide: isCollapsed || headerDrawerRef.mode === 'over' }"
          (click)="toggleCollapsed()"
          class="arrow left-pointing"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </div>
        <div
          id="expandMenuBtn"
          data-cy="expand-menu-button"
          [ngClass]="{ hide: !isCollapsed || headerDrawerRef.mode === 'over' }"
          (click)="toggleCollapsed()"
          class="arrow right-pointing"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </mat-drawer>
    <div class="header-item">
      <mat-icon
        id="showMenuBtn"
        style="padding-left: 15px;"
        [ngClass]="{'hide': headerDrawerRef.mode !== 'over'}"
        (click)="toggleOpen()"
      >menu</mat-icon>
    </div>
    <div class="header-item">
      <app-release-notes-notifications [_allReleaseNotes]="allReleaseNotes"></app-release-notes-notifications>
      <app-help-menu
        [_problemTypes]="problemTypes"
        [_workCenters]="workCenters"
        [_user]="currentUser"
      > </app-help-menu>
      <app-settings-icon></app-settings-icon>
      <app-notification-icon></app-notification-icon>
      <app-profile-icon [currentUser]="currentUser"></app-profile-icon>
    </div>
  </mat-drawer-container>

  <mat-drawer-container
    (backdropClick)="close()"
    class="drawer-container content-drawer-container"
    autosize
  >
    <mat-drawer
      id="contentDrawer"
      #drawerContent
      class="launch-pad-sidenav mat-elevation-z5"
      [ngClass]="{'collapsed-drawer': isCollapsed}"
      [opened]="isOpen"
      [mode]="displayMode"
      #contentDrawerRef
    >
      <app-sidenav
        (featureClicked)="sidenavFeatureClicked($event)"
        [workCenters]="workCenters"
        [user]="currentUser"
        [isCollapsed]="isCollapsed"
      ></app-sidenav>
    </mat-drawer>
    <div class="router-outlet-content">
      <app-banner [_banners]="launchPadSettings.Banners"></app-banner>
      <router-outlet></router-outlet>
      <div
        [hidden]="shouldHideWelcome"
        class="landing-page-welcome"
      >
        <div class="quick-find">
          <mat-card appearance="raised">
            <mat-card-content>
              <form
                [formGroup]="QuickFindGroup"
                style="width: 100%;"
              >
                <mat-form-field
                  focused
                  appearance="outline"
                  style="width: 100%;"
                  data-cy="quick-search-bar"
                >
                  <mat-label>Quick Search</mat-label>
                  <mat-icon matPrefix>search</mat-icon>
                  <input
                    type="text"
                    [formControl]="QuickFindGroup.get('DisplayName')"
                    placeholder="Search for an App..."
                    matInput
                    [matAutocomplete]="appListAutocomplete"
                    data-cy="app-quick-search"
                  >
                  <mat-autocomplete #appListAutocomplete="matAutocomplete">
                    @for (app of filterAppList | async; track app) {
                    <mat-option
                      [value]="app.DisplayName"
                      (onSelectionChange)="navigate(app.Link)"
                      [attr.data-cy]="app.DisplayName"
                    >
                      {{app.DisplayName}}
                    </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
        @if (currentUser) {
        <div class="quick-links-container">
          @for (quickLink of quickLinks; track quickLink) {
          @if (hasPermissionToViewQuickLink(quickLink)) {
          <mat-card
            appearance="raised"
            (click)="navigate(quickLink.Link)"
            class="quick-link"
            [attr.data-cy]="quickLink.DisplayName"
          >
            <div class="link">
              <button mat-icon-button>
                            <mat-icon>{{quickLink.Icon}}</mat-icon>
                          </button>
              <div class="quick-link-name">{{quickLink.DisplayName}}</div>
            </div>
          </mat-card>
          }
          }
        </div>
        }
      </div>
    </div>
  </mat-drawer-container>
</div>
