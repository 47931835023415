import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { NotificationOverlayComponent } from '../components/notification-overlay/notification-overlay.component';
import { CustomOverlayConfig } from '../models/notification-overlay';
import { NotificationOverlayRef } from '../models/notification-overlay-ref';
import { NOTIFICATION_OVERLAY_DATA } from './notification-overlay.injection-token';

@Injectable()

export class NotificationOverlayService {

  private readonly DEFAULT_CONFIG: CustomOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-transparent-backdrop',
    panelClass: 'notification-dialog-panel',
    width: 500
  };

  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay
  ) { }

  open(origin: HTMLButtonElement) {

    const overlayConfig = this.getOverlayConfig(origin, this.DEFAULT_CONFIG);
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.overlay.create(overlayConfig);

    // Instantiate remote control
    const dialogRef = new NotificationOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, this.DEFAULT_CONFIG, dialogRef);

    overlayRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });

  }

  private attachDialogContainer(overlayRef: OverlayRef, config: CustomOverlayConfig, dialogRef: NotificationOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(NotificationOverlayComponent, null, injector);
    overlayRef.attach(containerPortal);

  }

  private createInjector(config: CustomOverlayConfig, dialogRef: NotificationOverlayRef): PortalInjector {

    const injectionTokens = new WeakMap();

    injectionTokens.set(NotificationOverlayRef, dialogRef);
    injectionTokens.set(NOTIFICATION_OVERLAY_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(origin: HTMLButtonElement, config: CustomOverlayConfig): OverlayConfig {

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withViewportMargin(10)
      .withPush()
      .withPositions(this.getPositions());

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top'
      }
    ];
  }
}
