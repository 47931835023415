import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IFeatureModel } from 'src/app/models/feature-model';
import { IWorkCenterModel } from 'src/app/models/work-center-model';
import { SettingsService } from 'src/app/services/settings.service';
import { UserService } from 'src/app/services/user.service';
import { SettingsStoreService } from 'src/app/stores/settings-store-service.service';

import { UserStoreService } from '../stores/user-store.service';


@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService  {

  workCenters: IWorkCenterModel[];

  currentRoute = '';
  currentUrlSegments: string[] = [];

  private readonly forbiddenRoute = new ReplaySubject<string>(1);
  public forbiddenRoute$: Observable<string>;

  constructor(
    private readonly settingsService: SettingsService,

    private readonly settingsStoreService: SettingsStoreService,
    private readonly userStoreService: UserStoreService,
    private readonly userService: UserService,

    private readonly router: Router,
    private readonly location: Location
  ) {
    this.forbiddenRoute$ = this.forbiddenRoute.asObservable();
    this.userService.getCurrentUser();
    this.settingsService.getLaunchPadSettings();
    router.events.pipe(
      filter((e) => e instanceof NavigationCancel) // Catch any forbidden routes within the launchpad
    ).subscribe((event: NavigationCancel) => {
      this.forbiddenRoute.next(event.url);
    });

    router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
    ).subscribe((event: any) => {

      this.redirectRequired();
      if (event.url !== event.urlAfterRedirects && event.urlAfterRedirects === '/404'){ // Handle 404s
        this.forbiddenRoute.next(event.url); // Catch route that user was trying to view before being redirected
        // https://github.com/angular/angular/issues/16981#issuecomment-397218203
        this.location.replaceState(event.url); // Doing this so there is a consistent behavior for 404s & 403s
      }
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentRoute = state.url;
    this.currentUrlSegments = state.url.substring(1).split('/');
    return combineLatest([this.settingsStoreService.getSettings(), this.userStoreService.getCurrentUser()])
      .pipe(
        map(([settings, userInformation]) => {
          return this.userHasPermissionToViewRoute( settings?.WorkCenters, userInformation );
        })
      );
  }

  private userHasPermissionToViewRoute(workCenters: IWorkCenterModel[], userInformation: UserInformation): boolean{
    const matchingWorkCenter: IWorkCenterModel = this.getWorkCenterThatMatchesRoute(workCenters);
    if (matchingWorkCenter) {
      return this.checkWhatUserIsTryingToView(matchingWorkCenter, userInformation);
    }
    return true; // User is visiting a route that isn't in settings so it doesn't have permissions.
  }

  private getWorkCenterThatMatchesRoute(workCenters: IWorkCenterModel[]): IWorkCenterModel {
    if(workCenters) {
      for ( const workCenter of workCenters){
        if (workCenter?.Route === this.currentUrlSegments[0] + '/'){
          return workCenter;
        }
      }
    }
    return null;
  }

  private checkWhatUserIsTryingToView(matchingWorkCenter: IWorkCenterModel, userInformation: UserInformation) {
    if (this.currentUrlSegments.length > 1){ // User is trying to view a feature
      if (matchingWorkCenter?.Features) {
        const matchingFeature: IFeatureModel = this.getFeatureThatMatchesRoute(matchingWorkCenter.Features);
        if (matchingFeature && this.userHasPermissionToViewFeature(matchingFeature, userInformation)){
          return true;
        }
        else if (!matchingFeature && this.userHasPermissionToViewWorkCenter(matchingWorkCenter, userInformation)){
          return true;
        }
      }
    }
    else{ // User is just trying to view a work center route
      if (this.userHasPermissionToViewWorkCenter(matchingWorkCenter, userInformation)) {
        return true;
      }
    }

    // If true hasn't been returned yet, the user doesn't have permission
    const urlThatWasTryingToBeAccessed = this.currentUrlSegments.join('/');
    this.router.navigate(['/403']).then(() => {
      // https://github.com/angular/angular/issues/16981#issuecomment-549330207
      this.location.replaceState(urlThatWasTryingToBeAccessed);
    });
    return false;
  }

  private getFeatureThatMatchesRoute(features: IFeatureModel[]): IFeatureModel {
    for ( const feature of features){
      if (feature?.Route === this.currentUrlSegments[1] + '/'){
        return feature;
      }
    }
    return null;
  }

  userHasPermissionToViewFeature(feature, userInformation: UserInformation): boolean {
    if (feature.hasOwnProperty('Permissions') && feature?.Permissions && userInformation?.GroupMembership){
      for (const membership of userInformation?.GroupMembership) {
        if (feature?.Permissions.includes(membership) || feature?.Permissions.includes('__EVERYONE__') ) {
          return true;
        }
      }
    }
    return false;
  }

  userHasPermissionToViewWorkCenter(workcenter: IWorkCenterModel, userInformation: UserInformation): boolean {
    for (const feature of workcenter?.Features) {
      if (this.userHasPermissionToViewFeature(feature, userInformation)) {
        return true;
      }
    }
    return false;
  }

  redirectRequired(){
    const route = this.location.path();
    const distributorId = this.getDistributorId(route);
    if(distributorId){
      if(!isNaN(parseInt(distributorId, 10))){
        this.router.navigate(['Distributor', 'ManageDistributors', 'Users', distributorId], { replaceUrl: true }); // was going to Distributor/ManageUsers with id
      }
      else{
        this.router.navigate(['Distributor', 'ManageDistributors'], { replaceUrl: true }); // was going to Distributor/ManageUsers directly
      }
    }
  }

  getDistributorId(route: string): string{
    let distributorId = '';
    if (route.toLowerCase().includes('distributor/manageusers')){
      distributorId = route.match(/([^\/]+$)/)[0]; // should only be 1 item
    }
    return distributorId;
  }

}
