@import "../../../styles/variables";

#WorkCenterNav {
  .icons {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .feature-label {
    cursor: pointer;
    font-size: 16px;
    color: $gray_E;
    display: flex;
  }

  .hide {
    display: none;
  }

  .mat-accordion .mat-expansion-panel:first-of-type,
  .mat-accordion .mat-expansion-panel:last-of-type {
    border-radius: 0;
  }

  .mat-expansion-panel {
    background-color: #444;
  }

  .mat-expansion-panel-header-title {
    font-size: 20px;
    font-weight: 400;
    color: #eee;
    align-items: center;
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0;

      a {
        display: block;
        margin: 0;
        padding: 10px 16px 10px 32px;

        &:hover {
          text-decoration: none;
          background: #424242;
        }

        &.active-feature {
          background-color: #555;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    padding: 0 20px;
  }

  .menu-button {
    background-color: transparent;
    border: none;
  }
}

.collapsed-work-center-menu.mat-mdc-menu-panel {
  position: absolute;
  left: 45px;
  top: -35px;
  background-color: #424242;
  color: #eee;

  .mat-mdc-menu-item {
    color: #eee;
  }

  .mat-toolbar {
    background-color: #424242;
    color: #eee;
    height: 30px;
    font-size: 18px;
  }
}

.hunter {
  background-color: #212121;
  justify-content: center;

  img {
    height: 40px;
  }
}

#WorkCenterNav.hide-features {
  .mat-expansion-panel-content {
    display: none;
  }
}

mat-panel-title { //cutting off g in reporting
  padding: 1px 0;
}
