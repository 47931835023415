import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { Subscription } from 'rxjs';
import { localAppConfig } from 'src/app/app-config';
import { UserStoreService } from 'src/app/stores/user-store.service';

@Component({
  selector: 'app-usersnap',
  template: '<!-- Usersnap script placeholder -->'
})
export class UsersnapComponent implements AfterViewInit {
  private readonly rxSubs = new Subscription();

  projectApiKey: string = "38620856-84e8-4013-9d51-76d8880580fb";  // Not a secret key.
  constructor (
    private readonly userStoreService: UserStoreService,
    private readonly componentRef: ElementRef,
    @Inject(DOCUMENT) private document: Document) { }

  setUserData(api: any, user: UserInformation): void {
    api.setValue('visitor', user.Email);
    api.setValue('custom', {
      userId: user.UserId,
      userType: user.AccountType,
      title: user.JobTitle,
    });
  }

  initializeUsersnapApi(api: any): void {
    this.rxSubs.add(
      this.userStoreService.getCurrentUser().subscribe((user) => {
        api.init();
        api.on('open', (event: any) => this.setUserData(event.api, user));
      })
    );
  }

  injectUsersnapScript(): void {
    const script = document.createElement("script");
    script.defer = false;
    script.type = "text/javascript";
    script.src = `https://widget.usersnap.com/load/${this.projectApiKey}?onload=onUsersnapCXLoad`;

    this.document.head.appendChild(script);

    // Remove the component tag now that it's done its job.
    var element = this.componentRef.nativeElement;
    element.parentElement.removeChild(element);
  }

  ngAfterViewInit(): void {
    if (localAppConfig.EnableUsersnap) {
      (this.document.defaultView as any).onUsersnapCXLoad = (api: any) => this.initializeUsersnapApi(api);

      this.injectUsersnapScript();
    }
  }
}
