import { Component, Input, OnInit } from '@angular/core';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { IFeatureModel } from 'src/app/models/feature-model';
import { IWorkCenterModel } from 'src/app/models/work-center-model';
import { SupportTicketService } from 'src/app/services/support-ticket.service';
import { SettingsStoreService } from 'src/app/stores/settings-store-service.service';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
})
export class HelpMenuComponent implements OnInit {

  @Input()
  set _problemTypes(value: string[]) {
    this.problemTypes = value;
  }
  @Input()
  set _workCenters(value: IWorkCenterModel[]) {
    this.workCenters = value;
  }
  @Input()
  set _user(value: UserInformation) {
    this.user = value;
  }

  currentFeature: string;
  documentation: string = '';
  problemTypes: string[] = [];
  workCenters: IWorkCenterModel[] = [];
  user: UserInformation;

  constructor(
    private readonly supportTicketService: SupportTicketService,
    private readonly settingsStoreService: SettingsStoreService
  ) {}

  ngOnInit(): void {
    this.getCurrentFeatureDocumentation();
  }

  private getCurrentFeatureDocumentation() {
    this.settingsStoreService.getCurrentFeature().subscribe((feature: IFeatureModel) => {
      this.documentation = feature?.DocumentationLink;
    });
  }

  openSupportDialog() {
    this.supportTicketService.openSupportDialog(this.problemTypes);
  }

}
