<div class="error-container">
  @if (errorType === errorStatus.NotFound) {
  <div data-cy="404-page">
    <div class="error-description">
      <h2>
        Page Not Found
      </h2>
      <div>
        The page or resource you are looking for was either not found or does not exist.
      </div>
      @if (forbiddenRoute) {
      <div>
        Create a ticket to notify IT about the issue.
        <button mat-raised-button color="primary"  (click)="openSupportDialog()">Create Ticket</button>
      </div>
      }
    </div>
  </div>
  }
  @if (errorType === errorStatus.Forbidden) {
  <div data-cy="403-page">
    <div class="error-description">
      <h2>
        Access Denied / Forbidden
      </h2>
      <div>
        The page or resource you are trying to reach is forbidden for some reason.
      </div>
      @if (forbiddenRoute) {
      <div>
        Create a ticket to request access to this page
        <button mat-raised-button color="primary" (click)="openSupportDialog()">Create Ticket</button>
      </div>
      }
    </div>
  </div>
  }
</div>
