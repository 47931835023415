import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-timeout-snack-bar',
  templateUrl: './timeout-snack-bar.component.html',
  styleUrls: ['./timeout-snack-bar.component.scss']
})
export class TimeoutSnackBarComponent implements OnInit {

  timeRemaining = 0;
  interval: ReturnType<typeof setInterval>;

  formattedTimeRemaining = '';

  constructor(
    private readonly snackBarRef: MatSnackBarRef<TimeoutSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit() {
    this.timeRemaining = 0;
    this.timeRemaining = this.data.timeRemaining;
    this.getFormattedTimeRemaining();

    this.decrementTimeRemaining();

    this.snackBarRef.afterDismissed().subscribe((response) => {
      clearInterval(this.interval);
      this.timeRemaining = 0;
    });
  }

  decrementTimeRemaining() {
    this.interval = setInterval(() => {
      this.timeRemaining--;
      this.getFormattedTimeRemaining();
      if (!this.timeRemaining) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  getFormattedTimeRemaining() {
    let formattedTimeRemaining = '';
    const hours = Math.floor(this.timeRemaining / 3600);
    const minutes = Math.floor((this.timeRemaining % 3600) / 60);
    const seconds = Math.floor(this.timeRemaining) % 60;
    if (hours) {
      formattedTimeRemaining = hours.toString() + ':';
    }
    if (minutes) {
      formattedTimeRemaining = `${formattedTimeRemaining}${this.addLeadingZero(minutes.toString(), '0', 2)}:`;
    }
    this.formattedTimeRemaining = formattedTimeRemaining  + this.addLeadingZero(seconds.toString(), '0', 2);
  }

  addLeadingZero(value: string, pad: string, length: number) {
    return (new Array(length + 1).join(pad) + value).slice(-length);
  }

  takeAction() {
    this.snackBarRef.dismissWithAction();
  }

}
