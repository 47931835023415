import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@hunter/authentication';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.logOut();
    this.removeAllOverlays();
  }

  removeAllOverlays() { // This is to prevent showing any overlays that were previously showing
    const overlay: Element = document.getElementsByClassName('cdk-overlay-container')[0];
    overlay.parentNode.removeChild(overlay);
  }

  signIn(){
    this.router.navigate(['/login'], { queryParams: [] });
  }
}
