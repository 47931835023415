import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { SharedErrorHandlerService } from './services/shared-error-handler.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(
    private readonly injector: Injector
  ) {}

  handleError(error) {
    const sharedErrorHandlerService = this.injector.get(SharedErrorHandlerService);
    sharedErrorHandlerService.handleError(error);
  }
}
