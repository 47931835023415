import { OverlayRef } from '@angular/cdk/overlay';

export class NotificationOverlayRef {

  constructor(private overlayRef: OverlayRef) { }

  isOpen(): boolean {
    return this.overlayRef.overlayElement !== null;
  }

  close(): void {
    this.overlayRef.dispose();
  }
}
