import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { Subscription } from 'rxjs';
import { AppStoreService } from 'src/app/app-store.service';
import { UserStoreService } from 'src/app/stores/user-store.service';

@Component({
  selector: 'app-fake-error',
  templateUrl: './fake-error.component.html',
  styleUrls: ['./fake-error.component.scss']
})
export class FakeErrorComponent implements OnInit {

  private readonly rxSubs = new Subscription();


  theError = {
    status: 504,
    message: 'test message',
    name: 'HttpErrorResponse'
  };

  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly userStoreService: UserStoreService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.rxSubs.add(
      this.userStoreService.getCurrentUser().subscribe((user) => {
        this.showErrorDialogOrRedirect(user);
      })
    );
  }

  generateErrorDialog(): void {
    this.appStoreService.setFeatureError(this.theError);
  }

  userIsCypressDev(user: UserInformation): boolean {
    const userUserId = user.UserId;
    const isCypressDev = userUserId === 'cypress_dev';
    return isCypressDev;
  }

  showErrorDialogOrRedirect(user: UserInformation): void | Promise<boolean> {
    if (this.userIsCypressDev(user)) {
      this.generateErrorDialog();
    }
    else {
      // router may be null in some unit tests
      this.router?.navigateByUrl('');
    }
  }
}
