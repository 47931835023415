<div
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  class="move-cursor"
>

  <h1 mat-dialog-title>Create New Support Ticket</h1>
  <form
    [formGroup]="supportTicketForm"
    (ngSubmit)="onSubmit(supportTicketForm.value)"
    data-cy="support-dialog"
  >
    <div mat-dialog-content>

      <div>
        @if (data?.ProblemTypes?.length > 1) {
        <mat-form-field
          appearance="outline"
          style="margin-bottom: 10px;"
        >
          <mat-label>What type of issue are you experiencing?</mat-label>
          <mat-select formControlName="Subject">
            @for (problemType of data.ProblemTypes; track problemType) {
            <mat-option [value]="problemType">{{problemType}}</mat-option>
            }
          </mat-select>
          <mat-error>
            @if (!supportTicketForm.get('Subject').valid && supportTicketForm.get('Subject').touched) {
            <span>Please select an Issue.</span>
            }
          </mat-error>
        </mat-form-field>
        }
        @if (data?.ProblemTypes?.length === 1) {
        <mat-form-field appearance="outline">
          <mat-label>Problem Type</mat-label>
          <input
            disabled
            matInput
            value="{{supportTicketForm.get('Subject').value}}"
            data-cy="problem-type-input"
          >
        </mat-form-field>
        }
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            formControlName="Description"
            rows="6"
          ></textarea>
          <mat-error>
            @if (supportTicketForm.get('Description').getError('minlength')) {
            <span>Please enter a Description.</span>
            }
            @if (supportTicketForm.get('Description').getError('maxlength')) {
            <span>Description is too long.</span>
            }
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <div
      mat-dialog-actions
      align="end"
    >
      <button mat-button type="button" (click)="onCancelClick()">CANCEL</button>
      <button mat-button type="submit" color="primary" [disabled]="!supportTicketForm.valid" >SUBMIT</button>
    </div>
  </form>
</div>
