[
  {
    "Grouping": "Accounting",
    "Links": [
        {
            "DisplayName": "492T Processing",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/e492Ts.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "492T Processing (Beta)",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/vueE492T.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "A/R Balance Over $20K",
            "Link": "https://apps.hunter.com/jqAccounting/arBalance/arBalance.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "APInquiry",
            "Link": "http://10.2.1.202/AirApps/APInquiry.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Audit File History",
            "Link": "https://apps.hunter.com/jqAccounting/inventoryAFHistory/inventoryAFHistory.html",
            "Icon": "timeline"
        },
        {
            "DisplayName": "Chart of Account Dimensions",
            "Link": "https://apps.hunter.com/jqAccounting/chartOfAccountDimensions/chartOfAccountDimensions.html",
            "Icon": "pie_chart"
        },
        {
            "DisplayName": "Commission Balance Forward",
            "Link": "https://apps.hunter.com/jqAccounting/balanceForward/balanceForward.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Credit Memos",
            "Link": "https://apps.hunter.com/jqSales/creditMemo/creditMemo.html",
            "Icon": "payment"
        },
        {
            "DisplayName": "Daily Orders by Part Number",
            "Link": "https://apps.hunter.com/jqSales/dailyPartOrders/dailyPartOrders.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Demo Order Approval Process",
            "Link": "https://apps.hunter.com/jqExpressOrders/demoOrders/demoOrders.html",
            "Icon": "verified_user"
        },
        {
            "DisplayName": "E-Credit",
            "Link": "https://apps.hunter.com/jqAccounting/eCredit/eCredit.html",
            "Icon": "payment"
        },
        {
            "DisplayName": "E-Credit - Canada",
            "Link": "https://apps.hunter.com/jqAccounting/eCredit/eCreditCanada.html",
            "Icon": "payment"
        },
        {
            "DisplayName": "E-Credit Multi-Post Audit",
            "Link": "https://apps.hunter.com/jqAccounting/eCredit/eCreditMultiPostAudit.html",
            "Icon": "payment"
        },
        {
            "DisplayName": "Unprocessed Transactions",
            "Link": "https://apps.hunter.com/jqAccounting/showIcreditpaymentPrompt/showIcreditpaymentPrompt.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Expired Commissions",
            "Link": "https://home.hunter.com/Account/ReviewCommissions/Paid/Monthly",
            "Icon": "money_off"
        },
        {
            "DisplayName": "FIA Rack Sales",
            "Link": "https://apps.hunter.com/jqAccounting/fiaRackSales/fiaRackSales.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Field Activity Inquiry",
            "Link": "https://apps.hunter.com/jqSales/fieldActivityInquiry/fieldActivityInquiry.html",
            "Icon": "directions_walk"
        },
        {
            "DisplayName": "Ford Monthly Sales",
            "Link": "https://apps.hunter.com/jqAccounting/fordSalesReport/fordSalesReport.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Freight Payment Analysis",
            "Link": "https://apps.hunter.com/jqAccounting/freightPaymentAnalysis/freightPaymentAnalysis.html",
            "Icon": "assessment"
        },
        {
            "DisplayName": "Inventory Levels",
            "Link": "https://apps.hunter.com/jqManufacturing/inventoryLevels/inventoryLevels.html",
            "Icon": "assessment"
        },
        {
            "DisplayName": "Memo-Bill Report",
            "Link": "https://apps.hunter.com/jqAccounting/memoBillReport/memoBillReport.html",
            "Icon": "assignment"
        },
        {
            "DisplayName": "National Account Sales Tax",
            "Link": "https://apps.hunter.com/jqAccounting/naSalesTax/naSalesTax.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Payments Received Log",
            "Link": "https://apps.hunter.com/jqAccounting/paymentsReceivedLog/paymentsReceivedLog.html",
            "Icon": "list"
        },
        {
            "DisplayName": "QCD Sales",
            "Link": "https://apps.hunter.com/jqAccounting/qcdSales/qcdSales.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Commission Transfers / Unassigned Sales",
            "Link": "https://apps.hunter.com/jqRegMgr/commissionTransfers/commissionTransfers.html",
            "Icon": "swap_horiz"
        },
        {
            "DisplayName": "Demo Equipment",
            "Link": "https://apps.hunter.com/jqRegMgr/demoEquipment/demoEquipment.html",
            "Icon": "build"
        },
        {
            "DisplayName": "Rebill Authorizations (RBA)",
            "Link": "https://apps.hunter.com/jqRegMgr/reBillAuthorizations/reBillAuthorizations.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Rep Commissions",
            "Link": "https://home.hunter.com/Account/ReviewCommissions/Paid/Monthly",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Rep Insurance Maintenance",
            "Link": "https://apps.hunter.com/jqAccounting/repInsurance/repInsurance.html",
            "Icon": "card_membership"
        },
        {
            "DisplayName": "State Tax Certificates",
            "Link": "https://apps.hunter.com/jqAccounting/stateTaxCertificates/stateTaxCertificates.html",
            "Icon": "card_membership"
        },
        {
            "DisplayName": "Stocking Order Receipts",
            "Link": "https://apps.hunter.com/jqAccounting/soReceipts/soReceipts.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Training Payments Log",
            "Link": "https://apps.hunter.com/jqAccounting/trainingCenterPayments/trainingCenterPayments.html",
            "Icon": "list"
        },
        {
            "DisplayName": "VAG Sales",
            "Link": "https://apps.hunter.com/jqExport/vagSales/vagSales.html",
            "Icon": "attach_money"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping": "Canada",
    "Links":  [
        {
            "DisplayName": "492T Processing",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/e492Ts.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "492T Processing (Beta)",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/vueE492T.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Canada Equipment",
            "Link": "https://apps.hunter.com/jqAccounting/canadaEquipment/canadaEquipment.html",
            "Icon": "build"
        },
        {
            "DisplayName": "Canada Support",
            "Link": "https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=HunterCanada",
            "Icon": "headset_mic"
        },
        {
            "DisplayName": "Demo-Equipment Orders",
            "Link": "https://apps.hunter.com/jqExpressOrders/demoOrders/demoOrders.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Distributor Contracts",
            "Link": "https://apps.hunter.com/jqMarketing/distributorContracts/distributorContracts.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Distributor Estimated Stock",
            "Link": "https://apps.hunter.com/jqSales/distributorEstimatedStock/distributorEstimatedStock.html",
            "Icon": "list"
        },
        {
            "DisplayName": "E-Credit - Canada",
            "Link": "https://apps.hunter.com/jqAccounting/eCredit/eCreditCanada.html",
            "Icon": "payment"
        },
        {
            "DisplayName": "House Orders",
            "Link": "https://apps.hunter.com/jqSales/houseOrders/houseOrders.html#/houseOrderSearch",
            "Icon": "list"
        },
        {
            "DisplayName": "OEM Commission Verifications",
            "Link": "https://apps.hunter.com/jqSales/oemCommissions/oemCommissions.html",
            "Icon": "verified_user"
        },
        {
            "DisplayName": "Open Stocking Orders",
            "Link": "https://apps.hunter.com/jqServiceCenter/openStockingOrders/openStockingOrders.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Part Attributes",
            "Link": "https://apps.hunter.com/jqSales/partNumberAttributeSets/partNumberAttributeSets.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Tire Changer Serial Numbers",
            "Link": "https://apps.hunter.com/jqEngineering/tireChangerSerials/tireChangerSerials.html",
            "Icon": "list"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping":"Distributors (INTL)",
    "Links":[
        {
            "DisplayName": "Express Catalog Viewer",
            "Link": "https://apps.hunter.com/jqExpressOrders/catalogViewer/catalogViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "Express Order System",
            "Link": "https://apps.hunter.com/jqExpressOrders/expressOrders/expressOrders.html",
            "Icon": "local_shipping"
        },
        {
            "DisplayName": "International Sales/Service Home",
            "Link": "https://export.hunter.com/",
            "Icon": "dashboard"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping":"Distributors (US/CAN)",
    "Links":[
        {
            "DisplayName": "Express Catalog Viewer",
            "Link": "https://apps.hunter.com/jqExpressOrders/catalogViewer/catalogViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "Express Order System",
            "Link": "https://apps.hunter.com/jqExpressOrders/expressOrders/expressOrders.html",
            "Icon": "local_shipping"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping": "Engineering",
    "Links":  [
        {
            "DisplayName": "Bulk Part Obsolete Tool",
            "Link": "https://apps.hunter.com/jqEngineering/bulkPartObsoleteTool/bulkPartObsoleteTool.html",
            "Icon": "extension"
        },
        {
            "DisplayName": "Document Browser",
            "Link": "https://apps.hunter.com/jqQA/documentBrowser/documentBrowser.html",
            "Icon": "search"
        },
        {
            "DisplayName": "Drawing Viewer",
            "Link": "https://apps.hunter.com/jqEngineering/drawingViewer/drawingViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "ECO Viewer",
            "Link": "https://apps.hunter.com/jqEngineering/ecoViewer/ecoViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "ECO/ED/RM Answer Center",
            "Link": "https://apps.hunter.com/jqEngineering/answerCenterQuestions/answerCenterQuestions.html",
            "Icon": "list"
        },
        {
            "DisplayName": "ED Viewer",
            "Link": "https://apps.hunter.com/jqEngineering/edViewer/edViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "Effectivity Viewer",
            "Link": "https://apps.hunter.com/jqEngineering/effectivityViewer/effectivityViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "Engineering Change Request",
            "Link": "https://apps.hunter.com/jqEngineering/ecRequest/ecRequest.html",
            "Icon": "assignment"
        },
        {
            "DisplayName": "Mechanical Design Standards",
            "Link": "https://apps.hunter.com/jqEngineering/mechanicalDesignStandards/mechanicalDesignStandards.html",
            "Icon": "list"
        },
        {
            "DisplayName": "MOS Bulk Change Tool",
            "Link": "https://apps.hunter.com/jqEngineering/mosBulkChanges/mosBulkChanges.html",
            "Icon": "build"
        },
        {
            "DisplayName": "MOS Viewer",
            "Link": "https://apps.hunter.com/jqEngineering/mosViewer/mosViewer.html",
            "Icon": "import_contacts"
        },
        {
            "DisplayName": "Obsolete Parts",
            "Link": "https://apps.hunter.com/jqEngineering/obsoleteParts/obsoleteParts.html",
            "Icon": "extension"
        },
        {
            "DisplayName": "Product Structure Tool",
            "Link": "https://apps.hunter.com/jqEngineering/productStructure/productStructure.html",
            "Icon": "build"
        },
        {
            "DisplayName": "Program Email Groups",
            "Link": "https://apps.hunter.com/jqIT/emailGroups/emailGroups.html",
            "Icon": "email"
        },
        {
            "DisplayName": "Spec Campaign Parts",
            "Link": "https://apps.hunter.com/jqEngineering/specParts/specParts.html",
            "Icon": "extension"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping": "Export",
    "Links":  [
        {
            "DisplayName": "Alternate Warehouse Equipment",
            "Link": "https://apps.hunter.com/jqAccounting/ewEquipment/ewEquipment.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Deutschland Order Shortages",
            "Link": "https://apps.hunter.com/jqExport/deutschlandOrderShortages/deutschlandOrderShortages.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Doc Builder",
            "Link": "https://apps.hunter.com/jqExport/docBuilder/docBuilder.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Doc Builder Part Descriptions",
            "Link": "https://apps.hunter.com/jqExport/docBuilder/partDescriptions.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Export Packing List",
            "Link": "https://apps.hunter.com/jqExport/exportPackingList/exportPackingList.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Export Pricing",
            "Link": "https://apps.hunter.com/jqExport/exportPricing/exportPricing.html",
            "Icon": "attach_money"
        },
        {
            "DisplayName": "Germany AR",
            "Link": "https://apps.hunter.com/jqExport/arReminders/arReminders.html",
            "Icon": "list"
        },
        {
            "DisplayName": "House Orders",
            "Link": "https://apps.hunter.com/jqSales/houseOrders/houseOrders.html#/houseOrderSearch",
            "Icon": "house"
        },
        {
            "DisplayName": "International Weekly Activity",
            "Link": "https://apps.hunter.com/jqExport/intlWeeklyActivity/intlWeeklyActivity.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Ready Allocation",
            "Link": "https://apps.hunter.com/jqExport/docBuilder/readyAllocation.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Export Sales Dashboard",
            "Link": "https://sites.hunter.com/ExportSalesDashboard",
            "Icon": "dashboard"
        },
        {
            "DisplayName": "ExportSales",
            "Link": "https://export.hunter.com/",
            "Icon": "dashboard"
        },
        {
            "DisplayName": "Ship-With Orders",
            "Link": "https://apps.hunter.com/jqSales/shipWithOrders/shipWithOrders.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Installation Report Reconciliation",
            "Link": "https://apps.hunter.com/jqExport/showDistributorsPrompt/showDistributorsPrompt.html",
            "Icon": "assignment"
        },
        {
            "DisplayName": "Winkeys Database",
            "Link": "https://apps.hunter.com/jqExport/showWinkeyPrompt/showWinkeyPrompt.html",
            "Icon": "folder"
        },
        {
            "DisplayName": "VAG Sales",
            "Link": "https://apps.hunter.com/jqExport/vagSales/vagSales.html",
            "Icon": "attach_money"
        }
    ],
    "ViewMore": false
  },
  {
      "Grouping": "Field Reps",
      "Links": [
          {
              "DisplayName": "E-Credit",
              "Link": "https://apps.hunter.com/jqAccounting/eCredit/eCredit.html",
              "Icon": "payment"
          },
          {
              "DisplayName": "Estimated Distributor Stock",
              "Link": "https://apps.hunter.com/jqSales/distributorEstimatedStock/distributorEstimatedStock.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Express Catalog Viewer",
              "Link": "https://apps.hunter.com/jqExpressOrders/catalogViewer/catalogViewer.html",
              "Icon": "import_contacts"
          },
          {
              "DisplayName": "Express Order System",
              "Link": "https://apps.hunter.com/jqExpressOrders/expressOrders/expressOrders.html",
              "Icon": "local_shipping"
          },
          {
              "DisplayName": "Field Log-File",
              "Link": "https://apps.hunter.com/jqQA/fieldLogFileUploads/fieldLogFileUploads.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Field Rep Data",
              "Link": "https://apps.hunter.com/jqMarketing/fieldReps/ngFieldReps.html",
              "Icon": "directions_walk"
          },
          {
              "DisplayName": "Mobile Service",
              "Link": "https://apps.hunter.com/jqMobileService/mobileService/desktop.html",
              "Icon": "smartphone"
          },
          {
              "DisplayName": "Quote Builder",
              "Link": "https://apps.hunter.com/jqExpressOrders/quoteBuilder/quoteBuilder.html",
              "Icon": "note_add"
          },
          {
              "DisplayName": "Rep Commissions",
              "Link": "https://home.hunter.com/Account/ReviewCommissions/Paid/Monthly",
              "Icon": "attach_money"
          },
          {
              "DisplayName": "Sales and Service Home Page",
              "Link": "https://salesandservice.hunter.com/Home.aspx",
              "Icon": "person"
          },
          {
              "DisplayName": "Sales Portal",
              "Link": "https://apps.hunter.com/jqMarketing/portal/salesPortal.html",
              "Icon": "attach_money"
          },
          {
              "DisplayName": "Scheduled Maintenance Program",
              "Link": "https://apps.hunter.com/jqMobileService/pmProgram/pmProgram.html",
              "Icon": "assignment"
          },
          {
            "DisplayName": "Service Portal",
            "Link": "https://apps.hunter.com/jqMarketing/portal/servicePortal.html",
            "Icon": "build"
        },
          {
              "DisplayName": "USB Application",
              "Link": "https://apps.hunter.com/jqMarketing/usbMaintenance/usbMaintenance.html",
              "Icon": "store"
          }
      ],
      "ViewMore": false
  },
  {
    "Grouping": "IT",
    "Links":  [
        {
            "DisplayName": "Database Browser Permissions",
            "Link": "https://apps.hunter.com/jqIT/databaseBrowserPermissions/databaseBrowserPermissions.html",
            "Icon": "https"
        },
        {
            "DisplayName": "Employee Tracker",
            "Link": "https://apps.hunter.com/jqIT/employeeTracker/vueEmployeeTracker.html",
            "Icon": "person"
        },
        {
            "DisplayName": "Green Screen Security",
            "Link": "https://apps.hunter.com/jqIT/greenScreenSecurity/greenScreenSecurity.html",
            "Icon": "security"
        },
        {
            "DisplayName": "Invoice Email Log",
            "Link": "https://apps.hunter.com/jqIT/invoiceEmailLog/invoiceEmailLog.html",
            "Icon": "email"
        },
        {
            "DisplayName": "IT Contact List",
            "Link": "https://apps.hunter.com/jqIT/itContacts/itContacts.html",
            "Icon": "contacts"
        },
        {
            "DisplayName": "Program Email Groups",
            "Link": "https://apps.hunter.com/jqIT/emailGroups/emailGroups.html",
            "Icon": "email"
        },
        {
            "DisplayName": "Program Email Log",
            "Link": "https://apps.hunter.com/jqIT/programEmailLog/programEmailLog.html",
            "Icon": "email"
        },
        {
            "DisplayName": "QBWC User Management",
            "Link": "https://apps.hunter.com/jqIT/qbwcUserManagement/qbwcUserManagement.html",
            "Icon": "how_to_reg"
        },
        {
            "DisplayName": "Rec/Insp Inventory Debugger",
            "Link": "https://apps.hunter.com/jqQA/inventoryDebugger/inventoryDebugger.html",
            "Icon": "dns"
        },
        {
            "DisplayName": "SSIS Package Logs",
            "Link": "https://apps.hunter.com/jqIT/SSISPackages/SSISPackages.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Main Daily Run Time Chart",
            "Link": "https://apps.hunter.com/jqIT/SSISPackages/dailySSISChart.html",
            "Icon": "show_chart"
        },
        {
            "DisplayName": "Tomcat Manager - DMS-SF-APP",
            "Link": "http://dms-sf-app.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - HQ-SF-APP",
            "Link": "http://hq-sf-app.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - RMS-SF-APP",
            "Link": "http://rms-sf-app.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - SRV-IWEB-01",
            "Link": "http://srv-iweb-01.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - SRV-IWEB-02",
            "Link": "http://srv-iweb-02.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - SRV-IWEB-03",
            "Link": "http://srv-iweb-03.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Tomcat Manager - SRV-IWEB-04",
            "Link": "http://srv-iweb-04.hunter.com/manager/html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Web App Security",
            "Link": "https://apps.hunter.com/jqIT/webSecurity/webSecurity.html",
            "Icon": "security"
        }
    ],
    "ViewMore": false
  },
  {
      "Grouping": "Manufacturing",
      "Links": [
          {
              "DisplayName": "Bridgeton Overtime Alerts",
              "Link": "https://apps.hunter.com/jqManufacturing/bridgetonOTAlerts/bridgetonOTAlerts.html",
              "Icon": "notification_important"
          },
          {
              "DisplayName": "Cable Testing Missing Part Notification",
              "Link": "https://apps.hunter.com/jqManufacturing/cableTestMissingPartNotification/cableTestMissingPartNotification.html",
              "Icon": "build"
          },
          {
              "DisplayName": "Capacity Planning",
              "Link": "https://apps.hunter.com/jqManufacturing/capacityPlanning/capacityPlanning.html",
              "Icon": "assignment"
          },
          {
              "DisplayName": "Chasing Parts Report (CPR)",
              "Link": "https://apps.hunter.com/jqManufacturing/chasingPartsReport/chasingPartsReport.html",
              "Icon": "assignment"
          },
          {
              "DisplayName": "Cleanroom Software",
              "Link": "https://apps.hunter.com/jqManufacturing/softwareOrders/softwareOrders.html",
              "Icon": "dns"
          },
          {
              "DisplayName": "Download Product History Excel File",
              "Link": "file:///\\\\Handel\\Public\\Product_History.xls",
              "Icon": "cloud_download"
          },
          {
              "DisplayName": "Export Performance",
              "Link": "file:///\\\\srv-public\\Manufacturing\\Export_Performance",
              "Icon": "cloud_download"
          },
          {
              "DisplayName": "Inspection Personnel",
              "Link": "https://apps.hunter.com/jqManufacturing/inspectionPersonnel/inspectionPersonnel.html",
              "Icon": "person"
          },
          {
              "DisplayName": "Inventory Levels",
              "Link": "https://apps.hunter.com/jqManufacturing/inventoryLevels/inventoryLevels.html",
              "Icon": "assessment"
          },
          {
              "DisplayName": "Labor Collection Error Check",
              "Link": "https://apps.hunter.com/jqManufacturing/laborErrorFix/laborErrorFix.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Bridgeton",
              "Link": "http://srv-collectdb/huntershopfloor/",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Bridgeton (beta)",
              "Link": "http://hq-sf-app.hunter.com/hunterShopfloor/management/shopFloorManagement.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Durant",
              "Link": "http://dms-sf-app:8080/huntershopfloor/",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Durant (beta)",
              "Link": "http://dms-sf-app.hunter.com/hunterShopfloor/management/shopFloorManagement.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Raymond",
              "Link": "http://rms-sf-app:8080/huntershopfloor/",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Management - Raymond (beta)",
              "Link": "http://rms-sf-app.hunter.com/hunterShopfloor/management/shopFloorManagement.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Shopfloor Work Station - Bridgeton",
              "Link": "http://hq-sf-app.hunter.com/hunterShopfloor/workStation/shopFloorWorkstation.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Shopfloor Work Station - Durant",
              "Link": "http://dms-sf-app.hunter.com/hunterShopfloor/workStation/shopFloorWorkstation.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Collection Shopfloor Work Station - Raymond",
              "Link": "http://rms-sf-app.hunter.com/hunterShopfloor/workStation/shopFloorWorkstation.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Labor Productivity",
              "Link": "https://apps.hunter.com/jqManufacturing/laborProductivity/laborProductivity.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Machine Down Time",
              "Link": "https://apps.hunter.com/jqManufacturing/machineDownTime/machineDownTime.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Order Allocation Installation",
              "Link": "http://www2.hunter.com/AirApps/OrderAllocation.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "PCB/Cable QA",
              "Link": "https://apps.hunter.com/jqManufacturing/PCBNotebook/PCBNotebook.html",
              "Icon": "group_work"
          },
          {
              "DisplayName": "Quantity Short for Daily Shipments",
              "Link": "https://apps.hunter.com/jqManufacturing/dailyShippingShortages/dailyShippingShortages.html",
              "Icon": "local_shipping"
          },
          {
              "DisplayName": "Ready Allocation",
              "Link": "https://apps.hunter.com/jqExport/docBuilder/readyAllocation.html",
              "Icon": "note_add"
          },
          {
              "DisplayName": "Receiving Inspection Labels",
              "Link": "https://apps.hunter.com/jqManufacturing/receivingLabels/receivingLabels.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Review Allocation",
              "Link": "https://apps.hunter.com/jqManufacturing/reviewAllocation/reviewAllocation.html",
              "Icon": "rate_review"
          },
          {
              "DisplayName": "RoHS Compliance Stock Status",
              "Link": "https://apps.hunter.com/jqManufacturing/roHsStockStatus/roHsStockStatus.html",
              "Icon": "verified_user"
          },
          {
              "DisplayName": "Sales Forecast",
              "Link": "https://hunterengco-my.sharepoint.com/:f:/r/personal/adai_hunter_com/Documents/Final%20Forecast%20Files?csf=1&e=LHv2lc",
              "Icon": "trending_up"
          },
          {
              "DisplayName": "Serial Numbers",
              "Link": "https://apps.hunter.com/jqManufacturing/serialNumbers/serialNumbers.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Shipping Address Labels",
              "Link": "https://apps.hunter.com/jqManufacturing/shipAddressLabel/shipAddressLabel.html",
              "Icon": "local_shipping"
          },
          {
              "DisplayName": "Shipping Inquiry",
              "Link": "https://apps.hunter.com/jqManufacturing/shippingInquiry/shippingInquiry.html",
              "Icon": "local_shipping"
          },
          {
              "DisplayName": "Shipping Weights",
              "Link": "https://apps.hunter.com/jqSales/shippingWeights/shippingWeights.html",
              "Icon": "local_shipping"
          },
          {
              "DisplayName": "Shop Floor Control",
              "Link": "https://apps.hunter.com/jqManufacturing/shopFloorControl/shopFloorControl.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Training Log",
              "Link": "https://apps.hunter.com/jqManufacturing/factoryTrainingLog/factoryTrainingLog.html",
              "Icon": "list"
          },
          {
              "DisplayName": "Utilization List Download",
              "Link": "file:///\\\\srv-public\\Manufacturing\\Utilization",
              "Icon": "cloud_download"
          },
          {
              "DisplayName": "Chrome extension to enable download",
              "Link": "https://chrome.google.com/webstore/detail/enable-local-file-links/nikfmfgobenbhmocjaaboihbeocackld?hl=en",
              "Icon": "cloud_download"
          }
      ],
      "ViewMore": false
  },
  {
    "Grouping": "Marketing",
    "Links":  [
        {
            "DisplayName": "492T Processing",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/e492Ts.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "492T Processing (Beta)",
            "Link": "https://apps.hunter.com/jqAccounting/e492Ts/vueE492T.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "492T Survey",
            "Link": "https://apps.hunter.com/jqMarketing/e492tSurveyList/e492tSurveyList.html",
            "Icon": "list"
        },
        {
            "DisplayName": "492T Survey - User Charts",
            "Link": "https://apps.hunter.com/jqMarketing/e492tSurveyList/e492tSurveyCharts.html",
            "Icon": "bar_chart"
        },
        {
            "DisplayName": "Contact Submissions",
            "Link": "https://apps.hunter.com/jqMarketing/contactSubmissions/contactSubmissions.html",
            "Icon": "contacts"
        },
        {
            "DisplayName": "Distributor Contract Tool",
            "Link": "https://apps.hunter.com/jqMarketing/distributorContracts/distributorContracts.html",
            "Icon": "note_add"
        },
        {
            "DisplayName": "Distributor Primary Regions",
            "Link": "https://apps.hunter.com/jqMarketing/distributorPrimaryRegions/distributorPrimaryRegions.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Distributor Zip Codes",
            "Link": "https://apps.hunter.com/jqMarketing/distributorZipCodes/distributorZipCodes.html",
            "Icon": "list"
        },
        {
            "DisplayName": "DocuSign Contracts",
            "Link": "https://apps.hunter.com/jqMarketing/docuSign/docuSign.html",
            "Icon": "edit"
        },
        {
            "DisplayName": "Equipment Categories",
            "Link": "https://apps.hunter.com/jqMarketing/equipmentCategories/equipmentCategories.html",
            "Icon": "category"
        },
        {
            "DisplayName": "Field Activity Inquiry",
            "Link": "https://apps.hunter.com/jqSales/fieldActivityInquiry/fieldActivityInquiry.html",
            "Icon": "directions_walk"
        },
        {
            "DisplayName": "Field Rep Data",
            "Link": "https://apps.hunter.com/jqMarketing/fieldReps/ngFieldReps.html",
            "Icon": "directions_walk"
        },
        {
            "DisplayName": "Flat Rate Labor Guide",
            "Link": "https://apps.hunter.com/jqMarketing/flatRateLaborGuide/flatRateLaborGuide.html",
            "Icon": "rate_review"
        },
        {
            "DisplayName": "Part Warranty Manager",
            "Link": "https://apps.hunter.com/jqMarketing/partWarrantyManager/partWarrantyManager.html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Platinum Partners",
            "Link": "https://apps.hunter.com/jqSales/platinumPartners/platinumPartners.html",
            "Icon": "stars"
        },
        {
            "DisplayName": "PM Manager",
            "Link": "https://apps.hunter.com/jqMarketing/pmManager/pmManager.html",
            "Icon": "dns"
        },
        {
            "DisplayName": "Red Carpet Assignments",
            "Link": "https://apps.hunter.com/jqMarketing/redCarpet/redCarpet.html",
            "Icon": "grade"
        },
        {
            "DisplayName": "Field Activity Reports",
            "Link": "https://apps.hunter.com/jqRegMgr/fieldActivityReports/fieldActivityReports.html",
            "Icon": "assignment"
        },
        {
            "DisplayName": "Sales Activity Report",
            "Link": "https://apps.hunter.com/jqMarketing/salesActivityReport/salesActivityReport.html",
            "Icon": "assignment"
        },
        {
            "DisplayName": "Temporary Rep Assignments",
            "Link": "https://apps.hunter.com/jqMarketing/tempRepAssignments/tempRepAssignments.html",
            "Icon": "transfer_within_a_station"
        },
        {
            "DisplayName": "Territory Change Tool",
            "Link": "https://apps.hunter.com/jqMarketing/territoryChanges/territoryChanges.html",
            "Icon": "swap_horiz"
        },
        {
            "DisplayName": "USB Application",
            "Link": "https://apps.hunter.com/jqMarketing/usbMaintenance/usbMaintenance.html",
            "Icon": "store"
        }
    ],
    "ViewMore": false
  },
  {
    "Grouping": "Purchasing",
    "Links": [
        {
            "DisplayName": "Audit File Inquiry",
            "Link": "https://apps.hunter.com/jqAccounting/inventoryAFHistory/inventoryAFHistory.html",
            "Icon": "timeline"
        },
        {
            "DisplayName": "Buyer Code Changes",
            "Link": "https://apps.hunter.com/jqPurchasing/buyerCodeChanges/buyerCodeChanges.html",
            "Icon": "list"
        },
        {
            "DisplayName": "Download Product History Excel File",
            "Link": "file:///\\\\Handel\\Public\\Product_History.xls",
            "Icon": "cloud_download"
        },
        {
            "DisplayName": "Chrome extension to enable download",
            "Link": "https://chrome.google.com/webstore/detail/enable-local-file-links/nikfmfgobenbhmocjaaboihbeocackld?hl=en",
            "Icon": "cloud_download"
        },
        {
            "DisplayName": "Inventory Levels",
            "Link": "https://apps.hunter.com/jqManufacturing/inventoryLevels/inventoryLevels.html",
            "Icon": "assessment"
        },
        {
            "DisplayName": "Invoice Inquiry",
            "Link": "https://apps.hunter.com/jqPurchasing/purchasingInvoiceInquiry/purchasingInvoiceInquiry.html",
            "Icon": "list"
        },
        {
            "DisplayName": "MIR Charts",
            "Link": "http://10.2.1.202/AirApps/MIRCharts.html",
            "Icon": "assessment"
        },
        {
          "DisplayName": "PO Builder",
          "Link": "https://apps.hunter.com/jqPurchasing/poBuilder/poBuilder.html",
          "Icon": "build"
        },
        {
          "DisplayName": "Rec/Insp Inventory Debugger",
          "Link": "https://apps.hunter.com/jqQA/inventoryDebugger/inventoryDebugger.html",
          "Icon": "list"
        },
        {
          "DisplayName": "State Freight Carriers",
          "Link": "https://apps.hunter.com/jqPurchasing/stateFreightCarriers/stateFreightCarriers.html",
          "Icon": "local_shipping"
        },
        {
          "DisplayName": "Vendor Charts",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorCharts/vendorCharts.html",
          "Icon": "pie_chart"
        },
        {
          "DisplayName": "Vendor Evaluation Summary",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorEvaluation/vendorEvaluation.html",
          "Icon": "pie_chart"
        },
        {
          "DisplayName": "Vendor Open POs",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorService/vendorService.html",
          "Icon": "receipt"
        }
        ,
        {
          "DisplayName": "Vendor Service Evaluations",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorService/vendorService.html",
          "Icon": "list"
        }
    ],
    "ViewMore": false
},

{
  "Grouping": "Quality Assurance",
  "Links":  [
      {
          "DisplayName": "Camber Cell Burn In Test Results",
          "Link": "https://apps.hunter.com/jqQA/camberBurnInTest/camberBurnInTest.html",
          "Icon": "list"
      },
      {
          "DisplayName": "COA - Inspection Qty Override - Skip-Lot",
          "Link": "https://apps.hunter.com/jqQA/certificateOfAnalysis/certificateOfAnalysis.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Document Browser",
          "Link": "https://apps.hunter.com/jqQA/documentBrowser/documentBrowser.html",
          "Icon": "search"
      },
      {
          "DisplayName": "Field Log-File Uploads",
          "Link": "https://apps.hunter.com/jqQA/fieldLogFileUploads/fieldLogFileUploads.html",
          "Icon": "cloud_upload"
      },
      {
          "DisplayName": "Field Quality Alerts",
          "Link": "https://apps.hunter.com/jqQA/fieldQualityAlerts/fieldQualityAlerts.html",
          "Icon": "notification_important"
      },
      {
          "DisplayName": "Final Inspection",
          "Link": "https://apps.hunter.com/jqQA/finalInspection/finalInspection.html",
          "Icon": "verified_user"
      },
      {
          "DisplayName": "In-Process Inspection Reports",
          "Link": "https://apps.hunter.com/jqQA/inProcessInspectionReports/inProcessInspectionReports.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Inspection Personnel",
          "Link": "https://apps.hunter.com/jqManufacturing/inspectionPersonnel/inspectionPersonnel.html",
          "Icon": "person"
      },
      {
          "DisplayName": "Inventory Levels",
          "Link": "https://apps.hunter.com/jqManufacturing/inventoryLevels/inventoryLevels.html",
          "Icon": "assessment"
      },
      {
          "DisplayName": "Mercedes Benz QMW Data Import",
          "Link": "https://apps.hunter.com/jqQA/mbQmwImport/mbQmwImport.html",
          "Icon": "cloud_up"
      },
      {
          "DisplayName": "New Product Incident Tracker",
          "Link": "https://apps.hunter.com/jqQA/newProductIncidentTracker/newProductIncidentTracker.html",
          "Icon": "list"
      },
      {
          "DisplayName": "PCB/Cable QA",
          "Link": "https://apps.hunter.com/jqManufacturing/PCBNotebook/PCBNotebook.html",
          "Icon": "group_work"
      },
      {
          "DisplayName": "QIR Reminder Log",
          "Link": "https://apps.hunter.com/jqQA/qirReminderLog/qirReminderLog.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Rec/Insp Inventory Debugger",
          "Link": "https://apps.hunter.com/jqQA/inventoryDebugger/inventoryDebugger.html",
          "Icon": "dns"
      },
      {
          "DisplayName": "Repair Lab Phone Calls",
          "Link": "https://apps.hunter.com/jqQA/repairLabPhoneCall/repairLabPhoneCall.html",
          "Icon": "local_phone"
      },
      {
          "DisplayName": "Self Centering Adapter",
          "Link": "https://apps.hunter.com/jqQA/selfCenteringAdapter/selfCenteringAdapter.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Transition Plans",
          "Link": "https://apps.hunter.com/jqQA/transitionPlans/transitionPlans.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Vendor Evaluation Summary",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorEvaluation/vendorEvaluation.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Vendor Service Evaluations",
          "Link": "https://apps.hunter.com/jqPurchasing/vendorService/vendorService.html",
          "Icon": "list"
      }
  ],
  "ViewMore": false
},

{
  "Grouping": "Sales",
  "Links": [
      {
          "DisplayName": "Central Service",
          "Link": "https://apps.hunter.com/jqSales/centralService/centralService.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Batch Service Calls",
          "Link": "https://apps.hunter.com/jqSales/centralService/cenServBatchServiceCalls.html",
          "Icon": "call"
      },
      {
          "DisplayName": "Discount Tire Service Dashboard",
          "Link": "https://apps.hunter.com/jqSales/serviceData/serviceData.html",
          "Icon": "dashboard"
      },
      {
          "DisplayName": "Out of Territory Notifications",
          "Link": "https://apps.hunter.com/jqSales/repVacationNote/repVacationNote.html",
          "Icon": "notification_important"
      },
      {
          "DisplayName": "Problem Categories",
          "Link": "https://apps.hunter.com/jqSales/centralServiceWorkOrder/centralServiceProblemCategories.html",
          "Icon": "category"
      },
      {
          "DisplayName": "Work Order / Reporting",
          "Link": "https://apps.hunter.com/jqSales/centralServiceWorkOrder/centralServiceWorkOrder.html#/workOrderEntry",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Crane Van Calls",
          "Link": "https://apps.hunter.com/jqSales/craneVanCalls/craneVanCalls.html",
          "Icon": "call"
      },
      {
          "DisplayName": "Credit Memos",
          "Link": "https://apps.hunter.com/jqSales/creditMemo/creditMemo.html",
          "Icon": "payment"
      },
      {
          "DisplayName": "Daily Orders by Part Number",
          "Link": "https://apps.hunter.com/jqSales/dailyPartOrders/dailyPartOrders.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Distributor Estimated Stock",
          "Link": "https://apps.hunter.com/jqSales/distributorEstimatedStock/distributorEstimatedStock.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Download Product History Excel File",
          "Link": "file:///\\\\Handel\\Public\\Product_History.xls",
          "Icon": "cloud_download"
      },
      {
          "DisplayName": "Chrome extension to enable download",
          "Link": "https://chrome.google.com/webstore/detail/enable-local-file-links/nikfmfgobenbhmocjaaboihbeocackld?hl=en",
          "Icon": "cloud_download"
      },
      {
          "DisplayName": "Express Order System",
          "Link": "https://apps.hunter.com/jqExpressOrders/expressOrders/expressOrders.html",
          "Icon": "local_shipping"
      },
      {
          "DisplayName": "Catalog Viewer / Image Tool",
          "Link": "https://apps.hunter.com/jqExpressOrders/catalogViewer/catalogViewer.html",
          "Icon": "import_contacts"
      },
      {
          "DisplayName": "Maintenance Utility",
          "Link": "https://apps.hunter.com/jqExpressOrders/expressOrdersMaintenance/expressOrdersMaintenance.html",
          "Icon": "build"
      },
      {
          "DisplayName": "Usage Statistics",
          "Link": "https://apps.hunter.com/jqExpressOrders/statsReporting/statsReporting.html",
          "Icon": "timeline"
      },
      {
          "DisplayName": "User Management",
          "Link": "https://apps.hunter.com/jqExpressOrders/userManagement/userManagement.html",
          "Icon": "person"
      },
      {
          "DisplayName": "FAT Manager",
          "Link": "https://apps.hunter.com/jqSales/fatAssignmentsManager/fatAssignmentsManager.html",
          "Icon": "label"
      },
      {
          "DisplayName": "Field Activity Inquiry",
          "Link": "https://apps.hunter.com/jqSales/fieldActivityInquiry/fieldActivityInquiry.html",
          "Icon": "directions_walk"
      },
      {
          "DisplayName": "House Orders",
          "Link": "https://apps.hunter.com/jqSales/houseOrders/houseOrders.html#/houseOrderSearch",
          "Icon": "house"
      },
      {
          "DisplayName": "In-Process AS400 Orders",
          "Link": "https://apps.hunter.com/jqSales/inProcessOrders/inProcessOrders.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Lift Cable Letters",
          "Link": "https://apps.hunter.com/jqSales/liftCableLetters/liftCableLetters.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Literature Print Schedule",
          "Link": "https://apps.hunter.com/jqSales/literaturePrintSchedule/literaturePrintSchedule.html",
          "Icon": "today"
      },
      {
          "DisplayName": "Literature Reporting",
          "Link": "https://apps.hunter.com/jqSales/literatureReporting/literatureReporting.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "National Account Pricing",
          "Link": "http://www2.hunter.com/distribpricing/distribpricing.html",
          "Icon": "attach_money"
      },
      {
          "DisplayName": "National Accounts",
          "Link": "https://apps.hunter.com/jqAccounting/nationalAccount/nationalAccount.html",
          "Icon": "list"
      },
      {
          "DisplayName": "OEM Commission Verifications",
          "Link": "https://apps.hunter.com/jqSales/oemCommissions/oemCommissions.html",
          "Icon": "verified_user"
      },
      {
          "DisplayName": "Order Entry Alerts",
          "Link": "https://apps.hunter.com/jqSales/orderEntryAlerts/orderEntryAlerts.html",
          "Icon": "notification_important"
      },
      {
          "DisplayName": "Order Shipment Search",
          "Link": "https://apps.hunter.com/jqExpressOrders/orderShipmentSearch/orderShipmentSearch.html",
          "Icon": "search"
      },
      {
          "DisplayName": "Part Attribute Sets",
          "Link": "https://apps.hunter.com/jqSales/partNumberAttributeSets/partNumberAttributeSets.html",
          "Icon": "build"
      },
      {
          "DisplayName": "Partner Integration",
          "Link": "https://apps.hunter.com/jqSales/partnerIntegration/partnerIntegration.html",
          "Icon": "group_work"
      },
      {
          "DisplayName": "Platinum Partners",
          "Link": "https://apps.hunter.com/jqSales/platinumPartners/platinumPartners.html",
          "Icon": "group_work"
      },
      {
          "DisplayName": "Policy Concessions",
          "Link": "https://apps.hunter.com/jqSales/policyConcession/policyConcession.html",
          "Icon": "sentiment_satisfied_alt"
      },
      {
          "DisplayName": "Price List Viewer",
          "Link": "https://apps.hunter.com/jqSales/priceLists/priceLists.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Price Lookup Tool",
          "Link": "https://apps.hunter.com/jqExpressOrders/priceLookup/priceLookup.html",
          "Icon": "local_offer"
      },
      {
          "DisplayName": "National Accounts Gap Report",
          "Link": "https://apps.hunter.com/jqMarketing/rmPortal/naGapReport.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Regional Rollup Summary",
          "Link": "https://apps.hunter.com/jqMarketing/rmPortal/rmPortal.html#/regionRollup",
          "Icon": "list"
      },
      {
          "DisplayName": "Rep Changes",
          "Link": "https://apps.hunter.com/jqSales/reporting/repChanges.html",
          "Icon": "timeline"
      },
      {
          "DisplayName": "Unassigned Report",
          "Link": "https://apps.hunter.com/jqSales/reporting/unassignedTerritories.html",
          "Icon": "assignment"
      },
      {
          "DisplayName": "Sears Parts Invoices",
          "Link": "https://apps.hunter.com/jqSales/searsPartsInvoices/searsPartsInvoices.html",
          "Icon": "receipt"
      },
      {
          "DisplayName": "Ship-With Orders",
          "Link": "https://apps.hunter.com/jqSales/shipWithOrders/shipWithOrders.html",
          "Icon": "list"
      },
      {
          "DisplayName": "Shipping Weights",
          "Link": "https://apps.hunter.com/jqSales/shippingWeights/shippingWeights.html",
          "Icon": "local_shipping"
      },
      {
          "DisplayName": "USB Application",
          "Link": "https://apps.hunter.com/jqMarketing/usbMaintenance/usbMaintenance.html",
          "Icon": "store"
      }
  ],
  "ViewMore": false
},
{
"Grouping": "Service Center",
  "Links": [
    {
        "DisplayName": "Audit File History",
        "Link": "https://apps.hunter.com/jqAccounting/inventoryAFHistory/inventoryAFHistory.html",
        "Icon": "timeline"
    },
    {
        "DisplayName": "Commissionable Items",
        "Link": "https://apps.hunter.com/jqExpressOrders/expressOrdersMaintenance/expressOrdersMaintenance.html",
        "Icon": "list"
    },
    {
        "DisplayName": "Daily Cancelled Orders",
        "Link": "https://apps.hunter.com/jqServiceCenter/dailyCancelledOrders/dailyCancelledOrders.html",
        "Icon": "cancel_presentation"
    },
    {
        "DisplayName": "Daily Orders by Part Number",
        "Link": "https://apps.hunter.com/jqSales/dailyPartOrders/dailyPartOrders.html",
        "Icon": "list"
    },
    {
        "DisplayName": "Defective Equipment",
        "Link": "https://apps.hunter.com/jqServiceCenter/defectiveEquipment/defectiveEquipment.html",
        "Icon": "warning"
    },
    {
        "DisplayName": "Doc Builder",
        "Link": "https://apps.hunter.com/jqExport/docBuilder/docBuilder.html",
        "Icon": "note_add"
    },
    {
        "DisplayName": "Download Product History Excel File",
        "Link": "file://///Handel\\Public\\Product_History.xls",
        "Icon": "cloud_download"
    },
    {
        "DisplayName": "Chrome extension to enable download",
        "Link": "https://chrome.google.com/webstore/detail/enable-local-file-links/nikfmfgobenbhmocjaaboihbeocackld?hl=en",
        "Icon": "cloud_download"
    },
    {
        "DisplayName": "ECO Viewer",
        "Link": "https://apps.hunter.com/jqEngineering/ecoViewer/ecoViewer.html",
        "Icon": "import_contacts"
    },
    {
        "DisplayName": "Exchange Part Numbers",
        "Link": "https://apps.hunter.com/jqServiceCenter/exchangePartNumbers/exchangePartNumbers.html",
        "Icon": "swap_horiz"
    },
    {
        "DisplayName": "Express Order System",
        "Link": "https://apps.hunter.com/jqExpressOrders/expressOrders/expressOrders.html",
        "Icon": "assignment"
    },
    {
        "DisplayName": "Express Orders Review",
        "Link": "https://apps.hunter.com/jqExpressOrders/expressReview/expressReview.html",
        "Icon": "rate_review"
    },
    {
        "DisplayName": "HSC Inventory",
        "Link": "https://apps.hunter.com/jqServiceCenter/hscInventory/hscInventory.html",
        "Icon": "list"
    },
    {
        "DisplayName": "HSC Pick Tool",
        "Link": "https://apps.hunter.com/jqServiceCenter/hscPickTool/hscPickTool.html",
        "Icon": "local_offer"
    },
    {
        "DisplayName": "Line Item Count",
        "Link": "https://apps.hunter.com/jqServiceCenter/dailyLineCount/dailyLineCount.html",
        "Icon": "list"
    },
    {
        "DisplayName": "MOS VIEWER",
        "Link": "https://apps.hunter.com/jqEngineering/mosViewer/mosViewer.html",
        "Icon": "import_contacts"
    },
    {
        "DisplayName": "Open Stocking Orders",
        "Link": "https://apps.hunter.com/jqServiceCenter/openStockingOrders/openStockingOrders.html",
        "Icon": "list"
    },
    {
        "DisplayName": "Part Warranty Manager",
        "Link": "https://apps.hunter.com/jqMarketing/partWarrantyManager/partWarrantyManager.html",
        "Icon": "dns"
    },
    {
        "DisplayName": "Policy Concessions",
        "Link": "https://apps.hunter.com/jqSales/policyConcession/policyConcession.html",
        "Icon": "sentiment_satisfied_alt"
    },
    {
        "DisplayName": "Price Lookup Tool",
        "Link": "https://apps.hunter.com/jqExpressOrders/priceLookup/priceLookup.html",
        "Icon": "local_offer"
    },
    {
        "DisplayName": "Printer Warranty RGM Submissions",
        "Link": "https://apps.hunter.com/jqQA/printerRgmSubmissions/printerRgmSubmissions.html",
        "Icon": "list"
    },
    {
        "DisplayName": "Product Structure Tool",
        "Link": "https://apps.hunter.com/jqEngineering/productStructure/productStructure.html",
        "Icon": "build"
    },
    {
        "DisplayName": "Rep Batch Orders",
        "Link": "https://apps.hunter.com/jqServiceCenter/repBatchOrders/repBatchOrders.html",
        "Icon": "list"
    },
    {
        "DisplayName": "Repair Lab Phone Calls",
        "Link": "https://apps.hunter.com/jqQA/repairLabPhoneCall/repairLabPhoneCall.html",
        "Icon": "local_phone"
    },
    {
        "DisplayName": "Return To Stock As-Is Inquiry",
        "Link": "https://apps.hunter.com/jqServiceCenter/hscInventory/returnToStockAsIs.html",
        "Icon": "assignment"
    },
    {
        "DisplayName": "RGM Inventory Adjustments",
        "Link": "https://apps.hunter.com/jqServiceCenter/rgmInventoryAdjustments/rgmInventoryAdjustments.html",
        "Icon": "assignment"
    },
    {
        "DisplayName": "RGM Scraps Database",
        "Link": "https://apps.hunter.com/jqServiceCenter/rgmScraps/rgmScraps.html",
        "Icon": "dns"
    },
    {
        "DisplayName": "Service Center Support",
        "Link": "https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=ServiceCenter",
        "Icon": "headset_mic"
    },
    {
        "DisplayName": "Service Rep Invoices Eligible for Credit",
        "Link": "https://apps.hunter.com/jqServiceCenter/repInvoicesEligibleForCredit/repInvoicesEligibleForCredit.html",
        "Icon": "payment"
    },
    {
        "DisplayName": "Shipping Inquiry",
        "Link": "https://apps.hunter.com/jqManufacturing/shippingInquiry/shippingInquiry.html",
        "Icon": "local_shipping"
    },
    {
        "DisplayName": "Shipping Weights",
        "Link": "https://apps.hunter.com/jqSales/shippingWeights/shippingWeights.html",
        "Icon": "local_shipping"
    },
    {
        "DisplayName": "State Tax Certificates",
        "Link": "https://apps.hunter.com/jqAccounting/stateTaxCertificates/stateTaxCertificates.html",
        "Icon": "card_membership"
    },
    {
        "DisplayName": "Warehouse 90 Invoices",
        "Link": "https://apps.hunter.com/jqServiceCenter/hscDailyInvoices/hscDailyInvoices.html",
        "Icon": "list"
    }
],
"ViewMore": false
},
{
  "Grouping":"Support",
  "Links":[
     {
        "DisplayName":"Support - Advertising",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=Advertising",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"Facilities Management",
        "Link":"https://hunter.okta.com/home/hunterengineeringcompany_facilitiessupportfmx_1/0oa4eyyhirNeN8f99357/aln4ez2kt6U256HCJ357",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"E-CreditHunter Canada",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=HunterCanada",
        "Icon":"payment"

},
     {
        "DisplayName":"IT Support",
        "Link":"https://support.hunter.com",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"Service Center",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=ServiceCenter",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"Technical Publications",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=TechnicalPublications",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"Translations",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=Translations",
        "Icon":"headset_mic"

},
     {
        "DisplayName":"Video",
        "Link":"https://apps.hunter.com/jqIT/hunterSupportTasks/hunterSupportTasks.html?department=Video",
        "Icon":"play_circle_outline"

}

],
"ViewMore": false
}


]
