import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { UserControllerService, UserInformation } from '@hunter-service-libraries/user-service';

import { UserStoreService } from '../stores/user-store.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly userControllerService: UserControllerService,
    private readonly userStoreService: UserStoreService,
  ) {
  }

  getCurrentUser(){
    this.userControllerService.v2.getCurrentUser(
      { includeImage: false },
      { progressMessage: { verb: 'Retrieving', noun: 'user info' } }
    ).subscribe((user: UserInformation) => {
      this.userStoreService.setCurrentUser(user);
      datadogRum.setUserProperty('id', user.UserId);
      datadogRum.setUserProperty('name', user.DisplayName);
      datadogRum.setUserProperty('email', user.Email);
      datadogRum.setUserProperty('type', user.AccountType);
    });
  }
}
