import { Injectable } from '@angular/core';
import { SimpleStore } from '@hunter/state-management';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {

  private readonly currentUser = new SimpleStore<UserInformation>();


  constructor() { }

  setCurrentUser(currentUser: UserInformation): void {
    this.currentUser.setState(currentUser);
  }

  getCurrentUser(): Observable<UserInformation> {
    return this.currentUser.state$;
  }
}
