import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserInformation } from '@hunter-service-libraries/user-service';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { IFeatureModel } from 'src/app/models/feature-model';
import { IReleaseNote } from 'src/app/models/release-note';
import { IWorkCenterModel } from 'src/app/models/work-center-model';
import { SettingsStoreService } from 'src/app/stores/settings-store-service.service';

import * as data from '../../hunter-app-links.json';

@Component({
  selector: 'app-launch-pad-container',
  templateUrl: './launch-pad-container.component.html',
  styleUrls: ['./launch-pad-container.component.scss']
})
export class LaunchPadContainerComponent implements OnInit {

  @Input()
  set _launchPadSettings(value: any) {
    this.launchPadSettings = value;
    if (Object.keys(value).length > 0 && value.constructor === Object) {
      this.workCenters = value.WorkCenters;
      this.problemTypes = value.ProblemTypes;
      this.quickLinks = value.QuickLinks;
      this.featureLinks = this.buildFeatureLinks(value.WorkCenters);
      this.allReleaseNotes = value.ReleaseNotes?.length ? value.ReleaseNotes : [];
      this.setCurrentFeature();
    }
  }

  @Input()
  set _currentUser(value: UserInformation) {
    this.currentUser = value;
  }

  private readonly EVERYONE_PERMISSION = '__EVERYONE__';
  private readonly rxSubs = new Subscription();

  allSections = (data as any).default;

  shouldHideWelcome = true;
  homeUrl = location.origin;
  workCenters: IWorkCenterModel[] = [];
  quickLinks: [];
  problemTypes: string[] = [];
  isCollapsed = false;
  isOpen = false;
  displayMode = 'side';
  featureLinks = [];
  filterAppList: Observable<any[]>;
  allReleaseNotes: IReleaseNote[];

  appListAutoCompleteSource = [];

  QuickFindGroup: UntypedFormGroup;

  currentFeature: IFeatureModel;
  currentWorkCenter: IWorkCenterModel;

  currentUser: UserInformation;
  launchPadSettings: any;

  constructor(
    private readonly ngLocation: Location,
    private readonly settingsStoreService: SettingsStoreService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly breakpointObserver: BreakpointObserver,

  ) {
    this.toggleWelcome(this.ngLocation.path());
  }


  ngOnInit() {
    this.QuickFindGroup = this.formBuilder.group({
      DisplayName: ['']
    });
    this.filterAppList = this.QuickFindGroup.get('DisplayName').valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filter(value))
      );

    this.appListAutoCompleteSource = this.getDataSourceForQuickFind();

    this.rxSubs.add(this.breakpointObserver.observe(['(min-width: 600px)']).subscribe((result) => {
      if(result.matches){
        this.displayMode = 'side';
        this.isOpen = true;
        this.isCollapsed = true;

      }
      else{
        this.displayMode = 'over';
        this.isCollapsed = false;
        this.isOpen = false;
      }
    }));
    this.rxSubs.add(this.breakpointObserver.observe(['(min-width: 1280px)']).subscribe((result) => {
      this.isCollapsed = !result.matches;
    }));

    this.addDataCyToWorkCenters();

  }

  setCurrentFeature() {
    const { feature, workCenter } = this.getCurrentFeatureAndWorkCenter();
    if (feature && workCenter) {
      this.currentWorkCenter = this.workCenters.find((wc) => wc.Name.toLowerCase() === workCenter.toLowerCase());
      this.currentFeature = this.currentWorkCenter?.Features?.find((feat) => feat.Route.toLowerCase() === `${feature.toLowerCase()}/`);
      this.settingsStoreService.setCurrentFeature(this.currentFeature);
    }
  }

  // why do we need this? Great question. It turns out that stubbing window.location.href is a huge PITA for unit testing, so I made a method literally only to create a jasmine spy.
  private getCurrentFeatureAndWorkCenter(): { feature: string, workCenter: string } {
    const feature = window.location.href.split('/')[4];
    const workCenter = window.location.href.split('/')[3];
    return { feature, workCenter };
  }

  addDataCyToWorkCenters() {
    this.workCenters?.forEach((workCenter) => {
      workCenter.DataCy = this.formatNameToDataCy(workCenter.Name);
      workCenter?.Features?.forEach((feature) => {
        this.addDataCyToFeatures(feature);
      });
    });
  }

  private addDataCyToFeatures(feature: IFeatureModel) {
    feature.DataCy = this.formatNameToDataCy(feature.Name);
  }

  hasPermissionToViewQuickLink(quickLink): boolean {
    const hasPermissionGroup = quickLink.Permissions?.some((group) => {
      return group === this.EVERYONE_PERMISSION
      || this.currentUser?.GroupMembership.includes(group);
    });

    const excluded = quickLink.Exclusions?.some((group) => {
      return this.currentUser?.GroupMembership.includes(group);
    });

    return hasPermissionGroup && !excluded;
  }

  buildFeatureLinks(workCenters: IWorkCenterModel[]): any[]{
    const featureList = [];
    workCenters?.forEach((workCenter) => {
      workCenter?.Features?.forEach((feature) => {
        featureList.push(
          {
            DisplayName: workCenter.Name + ' - ' + feature.Name,
            Link: workCenter.Route + feature.Route + feature.DefaultRoute
          }
        );
      });
    });
    return featureList;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.appListAutoCompleteSource.filter((option) => option.DisplayName.toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    this.rxSubs.unsubscribe();
  }

  getDataSourceForQuickFind() {
    const allLegacyAppLinks = [];
    const usedLinks = [];
    let allAppLinks = [];
    this.allSections.forEach((section) => {
      section.Links.forEach((link) => {
        if (!usedLinks.includes(link.Link)) {
          usedLinks.push(link.Link);
          allLegacyAppLinks.push(link);
        }
      });
    });
    allAppLinks = allLegacyAppLinks.concat(this.featureLinks);
    allAppLinks.sort((a, b) => {
      const textA = a.DisplayName.toUpperCase();
      const textB = b.DisplayName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    return allAppLinks;
  }

  toggleCollapsed() {
    if (this.isOpen) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  navigate(link: string){
    window.open(link);
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }

  viewMoreToggle(section){
    section.ViewMore = !section.ViewMore;
  }

  sidenavFeatureClicked(feature) {
    if (this.displayMode === 'over' && feature) {
      this.close();
    }
  }

  private toggleWelcome(locPath: string) {

    this.shouldHideWelcome = locPath === '' || locPath === '/' ? false : true;

    this.ngLocation.onUrlChange((url) => {
      if (url === '' || url === '/') {
        this.shouldHideWelcome = false;
      }
      else {
        this.shouldHideWelcome = true;
      }
    });

  }

  private formatNameToDataCy(workCenterName: string) {
    const formattedName = this.replaceSpacesWithDashes(workCenterName.toLowerCase());
    const dataCy = `${formattedName}-drawer-item`;
    return dataCy;
  }

  private replaceSpacesWithDashes(str: string) {
    const result = str.replace(/\s+/g, '-');
    return result;
  }
}
