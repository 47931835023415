import { Component, OnInit } from '@angular/core';
import { AppStoreService } from 'src/app/app-store.service';

import { FeatureCommonService } from '../feature-common.service';

@Component({
  selector: 'app-open-territory-sales-call',
  template: `@if (initialized) {<open-territory-sales-call (featureErrorEvent)="handleError($event)" (feature403Event)="handle403($event)" (feature404Event)="handle404($event)"></open-territory-sales-call>}`,
})
export class OpenTerritorySalesCallComponent implements OnInit {
  initialized = false;
  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly featureCommonService: FeatureCommonService
  ) {}

  ngOnInit() {
    this.initialized = this.featureCommonService.initialize('open-territory-sales-call');
  }

  handleError(event){
    this.appStoreService.setFeatureError(event.detail);
  }

  handle403(event){
    this.appStoreService.setFeature403(event.detail);
  }

  handle404(event){
    this.appStoreService.setFeature404(event.detail);
  }

}
