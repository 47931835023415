<mt-material-theme></mt-material-theme>
<div id="LoginPageContainer">
  <div class="hunter-logo">
    <img src="assets/hunter_logo.png">
  </div>
  <div class="message">
    You have been signed out!
    <div>
      <button mat-raised-button (click)="signIn()">Sign In</button>
    </div>
  </div>

</div>
