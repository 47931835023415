import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IReleaseNote } from 'src/app/models/release-note';
import { ReleaseNoteEnum } from 'src/app/models/release-note-enum';

import { ReleaseNotesDialogComponent } from '../release-notes-dialog/release-notes-dialog.component';

@Component({
  selector: 'app-release-notes-notifications',
  templateUrl: './release-notes-notifications.component.html',
  styleUrls: ['./release-notes-notifications.component.scss']
})
export class ReleaseNotesNotificationsComponent implements OnInit {

  @Input()
  set _allReleaseNotes(releaseNotes: IReleaseNote[]) {
    this.allReleaseNotes = releaseNotes;
  }

  location: string;
  allReleaseNotes: IReleaseNote[];
  activeReleaseNotes: IReleaseNote[];
  unreadReleaseNoteCount: number;
  private readonly today = new Date();
  private readonly DAYS_UNTIL_EXPIRED = 90;
  private readonly expiredDate = new Date().setDate(this.today.getDate() - this.DAYS_UNTIL_EXPIRED);

  constructor(
    private router: Router,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.location = this.setTitle();
    this.setActiveReleaseNotes();
    this.setUnreadReleaseNotesCount();
  }

  private setTitle() {
    return this.router.url === '/' ? 'Landing Page' : document.title;
  }

  private setActiveReleaseNotes(): void {
    this.activeReleaseNotes = this.allReleaseNotes?.filter((notes) => this.noteIsPublished(notes) && !this.noteIsExpired(notes));
  }

  private noteIsExpired(note: IReleaseNote): boolean {
    return this.noteDateFormatted(note) < this.expiredDate;
  }

  private noteIsPublished(note: IReleaseNote): boolean {
    return this.today.getTime() >= this.noteDateFormatted(note);
  }

  private setUnreadReleaseNotesCount(): void {
    const launchPadNotes: IReleaseNote[] = this.activeReleaseNotes?.filter((note: IReleaseNote) => note.Type === ReleaseNoteEnum.LaunchPad );
    const filteredLaunchPad: IReleaseNote[] = this.filterOutAlreadyReadReleaseNotes(launchPadNotes, this.getUserLastReadReleaseNote(ReleaseNoteEnum.LaunchPad));
    const featureNotes: IReleaseNote[] = this.activeReleaseNotes?.filter((note: IReleaseNote) => note.Type === this.location || note.FeatureName === this.location);
    const filteredFeatures: IReleaseNote[] = this.filterOutAlreadyReadReleaseNotes(featureNotes, this.getUserLastReadReleaseNote(this.location));
    const total: number = filteredLaunchPad?.length + filteredFeatures?.length;
    this.unreadReleaseNoteCount = total;
  }

  private getUserLastReadReleaseNote(title: string): string {
    const BEGINNING_OF_EPOCH_TIME = '1/1/1970';
    return window.localStorage.getItem(this.formatTitleForLocalStorage(title)) || BEGINNING_OF_EPOCH_TIME;
  }

  private filterOutAlreadyReadReleaseNotes(notes: IReleaseNote[], date: string): IReleaseNote[] {
    return notes?.filter((note) =>  this.noteDateFormatted(note) > new Date(date).getTime());
  }

  openDialog(): void {
    this.matDialog.open(ReleaseNotesDialogComponent, {
      data: { releaseNotes: this.locationFilteredReleaseNotes(this.location) },
      width: '500px'
    }).afterClosed().subscribe(() => this.clearUnreadReleaseNotes());
  }

  locationFilteredReleaseNotes(title: string): IReleaseNote[] {
    const onlyNotesMatchingRequestedType = this.activeReleaseNotes?.filter((note) => note.Type === ReleaseNoteEnum.LaunchPad || note.FeatureName === title || note.Type === title );
    return this.sortReleaseNotesByTypeAndDate(onlyNotesMatchingRequestedType);
  }

  private clearUnreadReleaseNotes(): void {
    this.setUserLastReadReleaseNote(ReleaseNoteEnum.LaunchPad);
    this.setUserLastReadReleaseNote(this.location);
    this.setUnreadReleaseNotesCount();
  }

  private formatTitleForLocalStorage(title: string) {
    const formattedTitle = title?.replace(' ', '_').toUpperCase();
    return `${formattedTitle}_LAST_READ`;
  }


  private setUserLastReadReleaseNote(title: string): void {
    const date = this.findLatestDateInArrayOfNotes(title).toString();
    window.localStorage.setItem(this.formatTitleForLocalStorage(title), date);
  }
  // this method ensures that release notes released on the day a user views current release notes will still create a notification
  // if we set the localStorage date to new Date(), then releasenotes published on that date will have a time stamp at 0:00:00 and
  // would not trigger a notification.
  private findLatestDateInArrayOfNotes(title) {
    const relevantReleaseNotes = this.activeReleaseNotes?.filter((notes) => notes.Type === title || notes.FeatureName === title);
    const newestDateInReleaseNoteArray = new Date(Math.max(...relevantReleaseNotes?.map((note: IReleaseNote) => new Date(note.Date).getTime()))); // getting the newest date from the array of notes
    return isNaN(newestDateInReleaseNoteArray.getTime()) ? this.getUserLastReadReleaseNote(title) : newestDateInReleaseNoteArray;
  }

  private noteDateFormatted(note: IReleaseNote): number {
    return new Date(note.Date).getTime();
  };

  private sortReleaseNotesByTypeAndDate(notes: IReleaseNote[]): IReleaseNote[] {
    const notesByDate = notes?.sort((a, b) => {
      const time1 = new Date(a.Date).getTime();
      const time2 = new Date(b.Date).getTime();
      return time2 - time1;
    });

    return notesByDate?.sort((a, b) => a.Type.localeCompare(b.Type));

  }


}
