import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStoreService } from 'src/app/app-store.service';


@Injectable({
  providedIn: 'root'
})
export class FeatureCommonEventsService {
  private init = false;
  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly router: Router,
  ) {
    this.appStoreService.getFeature403().subscribe(() => {
      this.router.navigateByUrl('403');
    });
    this.appStoreService.getFeature404().subscribe(() => {
      this.router.navigateByUrl('404');
    });
  }

  initialize(){
    this.init = true;
  }

}
