import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@hunter/authentication';
import { EMPTY, Observable } from 'rxjs';

import { LockDownReasonEnum } from '../models/lock-down-reason-enum';
import { LockDownService } from './lock-down.service';

@Injectable({
  providedIn: 'root'
})
export class CallBlockerService implements HttpInterceptor {

  constructor(
    private readonly authService: AuthenticationService,
    private readonly lockDownService: LockDownService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // block any calls from being made if user isn't authenticated or if the user is locked down
    if (request.url.toLowerCase() !== 'assets/config/app-config.json') {
      if (!this.authService.isValid()) {
        if (!this.lockDownService.isUserLockedDown()) {
          this.lockDownService.lockUserDown(LockDownReasonEnum.Token);
        }
        return EMPTY;
      }
      else if (this.lockDownService.isUserLockedDown()) {
        return EMPTY;
      }
    }
    else {
      const configRequest = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      return next.handle(configRequest);
    }

    return next.handle(request);
  }
}
