import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonDialogComponent, DialogType, ICommonDialogConfig } from '@hunter/common-dialog';
import { ErrorStatus } from 'src/app/models/error-status';
import { ISupportFormModel } from 'src/app/models/support-form-model';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {
  supportTicketForm: UntypedFormGroup;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISupportFormModel,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.supportTicketForm = this.createFormGroup(this.formBuilder);
  }

  ngOnInit() {
    if (this.data.ProblemTypes.length === 1){
      this.supportTicketForm.get('Subject').setValue(this.convertErrorTypeToUserFriendlyError(this.data.ProblemTypes[0]));
    }
  }

  convertErrorTypeToUserFriendlyError(error: string){
    if (error === ErrorStatus.NotFound) {
      return 'Page not found';
    }
    else if (error === ErrorStatus.Forbidden) {
      return 'I need access to this page';
    }
    else {
      return 'I received error ' + error;
    }
  }

  createFormGroup(formBuilder: UntypedFormBuilder) {
    return formBuilder.group({
      Subject: [this.data?.SupportTicket?.Subject, [Validators.required]],
      Description: [this.data?.SupportTicket?.Description, [Validators.required, Validators.minLength(2), Validators.maxLength(3500)]],
      Data: this.data.SupportTicket?.Data
    });
  }

  onSubmit(formData): void {
    const confirmationMessage = 'Are you sure you want submit this support ticket?';
    this.openConfirmationDialog(confirmationMessage, formData);
  }

  onCancelClick(): void {
    const confirmationMessage = 'Are you sure you want cancel this support ticket?';
    this.openConfirmationDialog(confirmationMessage);
  }

  openConfirmationDialog(confirmationMessage: string, formData? ): void {
    const dialogConfig: ICommonDialogConfig = {
      Type: DialogType.Confirmation,
      Message: confirmationMessage
    };
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: dialogConfig,
      width: '300px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (formData) {
          this.dialogRef.close(formData);
        }
        else{
          this.dialogRef.close();
        }
      }
    });
  }

}
