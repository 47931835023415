export class AppConfig {
  Name: string;
  BaseUrl: string;
  MicroAppBaseUrl: string;
  NotificationRefreshInterval: number;
  InactivityTimeLimit: number;
  SessionExpirationWarning: number;
  ServiceDeskBaseUrl: string;
  EnableUsersnap: boolean;


  constructor(config?: any) {
    this.Name = config ? config.name : '';
    this.BaseUrl = config ? config.baseUrl : '';
    this.MicroAppBaseUrl = config ? config.microAppBaseUrl : '';
    this.InactivityTimeLimit = config ? config.inactivityTimeLimit : '';
    this.SessionExpirationWarning = config ? config.sessionExpirationWarning : '';
    this.ServiceDeskBaseUrl = config ? config.serviceDeskBaseUrl : '';
    this.EnableUsersnap = config && config.enableUsersnap;
  }
}

export let localAppConfig = new AppConfig();

export function setAppConfig(config: any): void {
  localAppConfig = new AppConfig(config);
}
