import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventModel, EventsControllerService, EventType } from '@hunter-service-libraries/launch-pad-service';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppStoreService } from 'src/app/app-store.service';
import { ErrorStatus } from 'src/app/models/error-status';
import { ISupportFormModel } from 'src/app/models/support-form-model';
import { PermissionGuardService } from 'src/app/services/permission-guard.service';
import { SupportTicketService } from 'src/app/services/support-ticket.service';

@Component({
  selector: 'app-error40x',
  templateUrl: './error40x.component.html',
  styleUrls: ['./error40x.component.scss']
})
export class Error40xComponent implements OnInit {
  private readonly rxSubs = new Subscription();
  supportForm: ISupportFormModel;
  errorType = '';
  route = '';
  forbiddenRoute = '';
  errorStatus = ErrorStatus;
  path = location.pathname.substr(1);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly eventsControllerService: EventsControllerService,
    private readonly permissionsGuardService: PermissionGuardService,
    private readonly supportTicketService: SupportTicketService,
    private readonly appStoreService: AppStoreService,
    private readonly locationService: Location
  ){
    this.route = this.activatedRoute.snapshot.url.toString();
    this.errorType = this.route;
  }

  ngOnInit() {
    this.rxSubs.add(this.getSubscriptionForForbiddenRoute());
    this.rxSubs.add(this.getFeature403());
    this.rxSubs.add(this.getFeature404());
    this.forbiddenRoute = this.checkForForbiddenRoute();
  }

  private getSubscriptionForForbiddenRoute() {
    return this.permissionsGuardService.forbiddenRoute$.pipe(
      tap((forbiddenRoute) => {
        if (forbiddenRoute) {
          this.logError(forbiddenRoute);
          this.forbiddenRoute = forbiddenRoute;
        }
      })
    ).subscribe();
  }

  private getFeature403() {
    return this.appStoreService.getFeature403().subscribe((route) => {
      this.logError(route);
      this.forbiddenRoute = route;
      this.locationService.replaceState(route);
    });
  }

  private getFeature404() {
    return this.appStoreService.getFeature404().subscribe((route) => {
      this.logError(route);
      this.forbiddenRoute = route;
      this.locationService.replaceState(route);
    });
  }

  private checkForForbiddenRoute(): string { // This is used for 403 errors that aren't triggered by the permission guard (403's from micro apps)
    if (!this.forbiddenRoute && this.path !== ErrorStatus.Forbidden && this.path !== ErrorStatus.NotFound) {
      this.logError(location.pathname);
      return location.pathname;
    }
    else {
      return this.forbiddenRoute;
    }
  }

  private logError(forbiddenRoute: string){
    const event: EventModel = {
      Route: forbiddenRoute,
      Type: this.determineErrorType()
    };
    if (event.Route){
      this.eventsControllerService.v1.addEvent(
        { event },
        { progressMessage: { verb: 'Creating', noun: 'event' } }
      ).pipe(take(1)).subscribe();
    }
  }

  private determineErrorType(){
    if (this.errorType === ErrorStatus.NotFound){
      return EventType.PageNotFound;
    }
    return EventType.AccessDenied;
  }

  openSupportDialog(): void {
    this.supportTicketService.openSupportDialog([this.errorType], this.forbiddenRoute);
  }


}
