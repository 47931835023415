import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { AppStoreService } from 'src/app/app-store.service';
import { ErrorHeaderTitleEnum } from 'src/app/models/error-header-title-enum';
import { ISupportFormModel } from 'src/app/models/support-form-model';
import { SupportTicketService } from 'src/app/services/support-ticket.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[custom-toast-component]',
  templateUrl: './custom-toast.component.html',
  preserveWhitespaces: false
})
export class CustomToastComponent extends Toast implements OnInit {
  refreshPageText = 'Please refresh the page and try again.';
  createSupportTicketText = 'If the issue persists, please create a support ticket.';
  responseMessageText = 'There may be a response message below that could be helpful.';

  errorMessages = {
    httpDefault: `Something occurred that we weren't anticipating. ${this.responseMessageText}
      If there is no response message, or it is not helpful, then please refresh the page and try again.
      ${this.createSupportTicketText}`,
    httpResponse400: `A validation error occurred while attempting to access or modify data.
      Please review the response message below, and update your request accordingly.
      If the issue persists, or the response message is not helpful, please create a support ticket.`,
    httpResponse401: `It appears that your credentials are not valid. They may have expired.
      ${this.refreshPageText} ${this.createSupportTicketText}.`,
    httpResponse403: `It appears that you may not have sufficient permissions to access or modify the requested data.
      ${this.responseMessageText} If you still have questions, please inquire with your manager,
      or create a support ticket.`,
    httpResponse404:`The resources that you are attempting to retrieve/use could not be found.
      Please double check the values/ids that are being submitted. Please create
      a support ticket if you think your requested resource does exist and this
      error is incorrect or if you don't know how to correct your request.`,
    httpResponse409:`A conflict occured with your request. ${this.responseMessageText}
      If you still have questions, please create a support ticket.`,
    httpResponse504:`A request you have made has timed out. There may have been an issue with our systems.
      ${this.refreshPageText} ${this.createSupportTicketText}`,
    default: `Something occurred that we weren't anticipating. ${this.refreshPageText} ${this.createSupportTicketText}`,
    customError888: `An issue occurred dismissing notifications. ${this.refreshPageText} ${this.createSupportTicketText}`
  };


  supportForm: ISupportFormModel;
  type = 'error';
  appError: any;
  toastHeaderTitle: string;
  toastHelpfulMessage: string;
  toastHttpResponseMessage;
  showAdvanced: false;

  constructor(
    private readonly appStoreService: AppStoreService,
    private readonly supportTicketService: SupportTicketService,
    toastrService: ToastrService,
    toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    const appError = this.appStoreService.getLatestError();
    this.appError = this.prepareAppErrorForSupportTicketDialog(appError);
    this.setToastProperties();
  }

  openErrorTicketDialog() {
    let problemType = this.appError.error.RequestId;
    if(typeof this.appError.error.ProblemType !== 'undefined' && this.appError.error.ProblemType !== problemType){
      problemType = this.appError.error.ProblemType;
    }
    this.supportTicketService.openSupportDialog([problemType as string], null, { 'FullError': JSON.stringify(this.appError, null, 2) });
    this.remove();
  }

  private prepareAppErrorForSupportTicketDialog(appError: any) {
    const errorObject = {
      RequestId: '-1',
      ProblemType: '-1'
    };
    if(!appError.error) {
      appError.error = errorObject;
    }
    if (!appError.error?.RequestId) {
      appError.error.RequestId = errorObject.RequestId;
      appError.error.ProblemType = errorObject.ProblemType;
    }

    switch (appError.status) {
      case 0:
        appError.error.ProblemType = '0 Unknown' ;
        break;
      case 400:
        appError.error.ProblemType = '400 Invalid Request' ;
        break;
      case 401:
        appError.error.ProblemType = '401 Unauthorized';
        break;
      case 403:
        appError.error.ProblemType = '403 Forbidden';
        break;
      case 404:
        appError.error.ProblemType = '404 Resource Not Found';
        break;
      case 409:
        appError.error.ProblemType = '409 Conflict';
        break;
      case 500:
        appError.error.ProblemType = '500 Internal Server Error';
        break;
      case 504:
        appError.error.ProblemType = '504 Gateway Timeout';
        break;
      default:
        if(appError.status) {
          appError.error.ProblemType = `status: ${appError.status}`;
        }
        break;
    }

    return appError;
  }

  setToastProperties() {
    const status = this.appError.status;
    switch (true) {
      case status === 400:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.InvalidRequest;
        this.toastHelpfulMessage = this.errorMessages.httpResponse400;
        break;
      case status === 401:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Unauthorized;
        this.toastHelpfulMessage = this.errorMessages.httpResponse401;
        break;
      case status === 403:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Unauthorized; // Using Unauthorized instead of Forbidden to reduce cortisol spikes in our users
        this.toastHelpfulMessage = this.errorMessages.httpResponse403;
        break;
      case status === 404:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.ResourceNotFound;
        this.toastHelpfulMessage = this.errorMessages.httpResponse404;
        break;
      case status === 409:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Conflict;
        this.toastHelpfulMessage = this.errorMessages.httpResponse409;
        break;
      case status === 504:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Timeout;
        this.toastHelpfulMessage = this.errorMessages.httpResponse504;
        break;
      case (status >= 0 && status <= 599 ):
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Sorry;
        this.toastHelpfulMessage = this.errorMessages.httpDefault;
        break;
      case status === 888:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Sorry;
        this.toastHelpfulMessage = this.errorMessages.customError888;
        break;
      default:
        this.toastHeaderTitle = ErrorHeaderTitleEnum.Sorry;
        this.toastHelpfulMessage = this.errorMessages.default;
        break;
    }
  }

  displayErrorProperty(value: any) {
    if (!value){
      return false;
    }
    else if (Array.isArray(value) && !value.length){
      return false;
    }
    return true;
  }

}
