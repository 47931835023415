import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CalculateRoiComponent } from './calculate-roi/calculate-roi.component';
import { CentralServiceWorkOrdersComponent } from './central-service-work-orders/central-service-work-orders.component';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { FeatureLoaderDialogComponent } from './feature-loader-dialog/feature-loader-dialog.component';
import { InternalPartImagesComponent } from './internal-part-images/internal-part-images.component';
import { ManageBannersComponent } from './manage-banners/manage-banners.component';
import { ManageDemoEquipmentComponent } from './manage-demo-equipment/manage-demo-equipment.component';
import { ManageDistributorUsersComponent } from './manage-distributor-users/manage-distributor-users.component';
import { ManageDistributorsComponent } from './manage-distributors/manage-distributors.component';
import { ManageFeatureFlagsComponent } from './manage-feature-flags/manage-feature-flags.component';
import { ManageNotificationsComponent } from './manage-notifications/manage-notifications.component';
import { ManageQuickLinksComponent } from './manage-quick-links/manage-quick-links.component';
import { ManageReleaseNotesComponent } from './manage-release-notes/manage-release-notes.component';
import { ManageSurveysComponent } from './manage-surveys/manage-surveys.component';
import { OnboardRepsComponent } from './onboard-reps/onboard-reps.component';
import { OpenTerritorySalesCallComponent } from './open-territory-sales-call/open-territory-sales-call.component';
import { OperationResourcesComponent } from './operation-resources/operation-resources.component';
import { PaymentPortalComponent } from './payment-portal/payment-portal.component';
import { PowerBiReportsComponent } from './power-bi-reports/power-bi-reports.component';
import { ProductionSchedulingComponent } from './production-scheduling/production-scheduling.component';
import { QuoteBuilderComponent } from './quote-builder/quote-builder.component';
import { ReviewCommissionsComponent } from './review-commissions/review-commissions.component';
import { SurveyPrototypeComponent } from './survey-prototype/survey-prototype.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { WidgetPrototypeComponent } from './widget-prototype/widget-prototype.component';

@NgModule({ declarations: [
  ManageDistributorsComponent,
  ManageNotificationsComponent,
  UserProfileComponent,
  UserSettingsComponent,
  ManageDistributorUsersComponent,
  UserPermissionsComponent,
  OnboardRepsComponent,
  DocumentDownloadComponent,
  PowerBiReportsComponent,
  CalculateRoiComponent,
  ReviewCommissionsComponent,
  FeatureLoaderDialogComponent,
  WidgetPrototypeComponent,
  CustomerInfoComponent,
  SurveyPrototypeComponent,
  ManageReleaseNotesComponent,
  ManageFeatureFlagsComponent,
  ManageDemoEquipmentComponent,
  ManageSurveysComponent,
  ManageBannersComponent,
  ManageQuickLinksComponent,
  OpenTerritorySalesCallComponent,
  InternalPartImagesComponent,
  OperationResourcesComponent,
  ProductionSchedulingComponent,
  PaymentPortalComponent,
  QuoteBuilderComponent,
  CentralServiceWorkOrdersComponent
],
exports: [
  ManageDistributorsComponent,
  ManageNotificationsComponent,
  UserProfileComponent,
  UserSettingsComponent,
  ManageDistributorUsersComponent,
  UserPermissionsComponent,
  OnboardRepsComponent,
  DocumentDownloadComponent,
  PowerBiReportsComponent,
  CalculateRoiComponent,
  ReviewCommissionsComponent,
  WidgetPrototypeComponent,
  CustomerInfoComponent,
  SurveyPrototypeComponent,
  ManageFeatureFlagsComponent,
  ManageDemoEquipmentComponent,
  ManageSurveysComponent,
  ManageBannersComponent,
  ManageQuickLinksComponent,
  OpenTerritorySalesCallComponent,
  InternalPartImagesComponent,
  OperationResourcesComponent,
  ProductionSchedulingComponent,
  PaymentPortalComponent,
  QuoteBuilderComponent,
  CentralServiceWorkOrdersComponent
],
schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CommonModule,
  MatProgressSpinnerModule,
  MatDialogModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class FeaturesModule { }
