import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-loader-dialog',
  templateUrl: './feature-loader-dialog.component.html',
  styleUrls: ['./feature-loader-dialog.component.scss']
})
export class FeatureLoaderDialogComponent {

  constructor() { }

}
